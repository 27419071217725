import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import CardGBP from "../../assets/icons/card-gbp.svg";
import CardUSD from "../../assets/icons/card-usd.svg";
import TransactionsMenu from "../dashboard/transactions-menu";
import TransactionsTable from "../../common/transactions";
import DownloadTransactionsModel from "../../common/transactions/download-transactions";

const transactionsData = [
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
];

const LinkedCard = ({ imgSrc, cardTitle, cardNumber, validDate }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        padding: { xs: "17px 18px", md: "17px 30px" },
        flexDirection: "row",
        alignItems: "center",
        mb: "15px",
        minHeight: "108px",
        height: "100%",
        "@media (min-width:1201px)": {
          minHeight: "115px",
        },
      }}
    >
      <Box sx={{ width: 80, mr: "18px" }}>
        <Box
          sx={{
            width: 80,
            height: 50,
            borderRadius: "5px",
          }}
        >
          <img
            src={imgSrc}
            alt="Card"
            style={{
              width: "100%",
              height: "100%",
              filter: "drop-shadow(6px 6px 6px #00000040",
            }}
          />
        </Box>
      </Box>
      <Box sx={{ flex: "1 1 auto", padding: "0 !important" }}>
        <Typography variant="h5" sx={{ fontSize: 18 }}>
          {cardTitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="body2" sx={{ pl: { lg: 4.5 } }}>
            {cardNumber}
          </Typography>
          <Typography variant="body2">{validDate}</Typography>
        </Box>
      </Box>
      <TransactionsMenu>
        <MoreVertRoundedIcon
          sx={{ color: "#000000", transform: "translateX(14px)" }}
        />
      </TransactionsMenu>
    </Card>
  );
};

const CopyableText = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <>
      <Typography
        variant="body2"
        flex={1}
        textAlign={"end"}
        title={text}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: 100,
          fontSize: { xs: 12, xl: 14 },
        }}
      >
        {text}
      </Typography>
      <IconButton
        onClick={handleCopy}
        sx={{
          ml: { xs: "10px", lg: "10px", xl: "25px" },
          p: 0,
        }}
      >
        {copied ? (
          <CheckRoundedIcon sx={{ color: "#000", fontSize: 18 }} />
        ) : (
          <ContentCopyRoundedIcon sx={{ color: "#000", fontSize: 18 }} />
        )}
      </IconButton>
    </>
  );
};

const Details = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [downloadTransactionsModel, setDownloadTransactionsModel] =
    useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <>
      <Box sx={{ my: { xs: "40px", sm: "60px", md: "70px", lg: "80px" } }}>
        <Grid
          container
          columnSpacing={{ md: "14px", xl: "24px" }}
          rowSpacing={{ xs: "24px", md: "0" }}
          columns={24}
        >
          <Grid item xs={24} md={12} xl={11}>
            <Typography variant="h6" mb={"20px"}>
              Account details
            </Typography>
            <List className="accountDetailsList">
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ width: { xs: 110, xl: 140 } }}>
                  Company name
                </Typography>
                <Stack direction={"row"} alignItems={"center"} flexGrow={1}>
                  <CopyableText text={"Pemmo Ltd."} />
                </Stack>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ width: { xs: 110, xl: 140 } }}>
                  Account number
                </Typography>
                <Stack direction={"row"} alignItems={"center"} flexGrow={1}>
                  <CopyableText text={"UK64CT0000010034567"} />
                </Stack>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ width: { xs: 110, xl: 140 } }}>
                  BIC
                </Typography>
                <Stack direction={"row"} alignItems={"center"} flexGrow={1}>
                  <CopyableText text={"PE8900UK"} />
                </Stack>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ width: { xs: 110, xl: 140 } }}>
                  Adresse
                </Typography>
                <Stack direction={"row"} alignItems={"center"} flexGrow={1}>
                  <CopyableText text={"30 Churchill Place,London, E14 5EU"} />
                </Stack>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={24} md={12} xl={13}>
            <Typography variant="h6" mb={"20px"}>
              Linked Cards
            </Typography>
            <Box>
              <LinkedCard
                imgSrc={CardUSD}
                cardTitle="Mastercard Pemmo"
                cardNumber="**** 8790"
                validDate="06/28"
              />
              <LinkedCard
                imgSrc={CardGBP}
                cardTitle="Mastercard Pemmo"
                cardNumber="**** 2030"
                validDate="03/26"
              />
            </Box>
          </Grid>
          <Grid item xs={24} sx={{ mt: "19px" }}>
            <Box
              sx={{
                backgroundColor: "secondary.main",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Box sx={{ padding: "16px" }}>
                <Typography variant="h3" component="h3">
                  Latest transactions
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#eaeaea",
                  display: "flex",
                  justifyContent: "end",
                  flexWrap: { xs: "wrap", sm: "unset" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    flexGrow: 1,
                  }}
                >
                  {isSearchOpen ? (
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search your transactions"
                      fullWidth
                      className="tableSearchInput"
                      sx={{ height: 32 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={toggleSearch}
                            sx={{ cursor: "pointer" }}
                          >
                            <SearchRoundedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <IconButton onClick={toggleSearch} sx={{ px: 1 }}>
                      <SearchRoundedIcon />
                    </IconButton>
                  )}
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: { xs: 127, md: 322 }, height: 32 }}
                  onClick={() => setDownloadTransactionsModel(true)}
                >
                  {isSmallScreen
                    ? "Download"
                    : "Download transactions as CSV or PDF"}{" "}
                  <EastRoundedIcon sx={{ fontSize: "20px" }} />
                </Button>
              </Box>
              <TransactionsTable data={transactionsData} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DownloadTransactionsModel
        open={downloadTransactionsModel}
        setOpen={setDownloadTransactionsModel}
        transactionsData={transactionsData}
      />
    </>
  );
};

export default Details;
