import { EastRounded } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import "flag-icons/css/flag-icons.min.css";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";

import AppleStore from "../../../assets/icons/apple-store.svg";
import GoogleStore from "../../../assets/icons/google-store.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import InviteIcon from "../../../assets/icons/invite.svg";
import NoteIcon from "../../../assets/icons/note.svg";
import WalletIcon from "../../../assets/icons/wallet-add.svg";
import Logo from "../../../assets/images/pemmo-logo.svg";
import RatingImg from "../../../assets/images/rating.svg";
import StarsImg from "../../../assets/images/stars.svg";
import YoungWomanImg from "../../../assets/images/young-woman.png";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import StepThree from "./step-three";

const validationStep2Schema = Yup.object({
  country: Yup.string().required("Country is required"),
});

const steps = [
  { label: "Registration", step: 1, component: <StepOne /> },
  { label: "Your company", step: 2, component: <StepTwo /> },
  { label: "Supporting documents", step: 3, component: <StepThree /> },
];

const OnBoarding = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onboardingSteps = [
    {
      icon: HomeIcon,
      primary: "You already have a company number",
      secondary: "You are a company owner, freelance, self-employed..",
      routeToStep: 1
    },
    {
      icon: WalletIcon,
      primary: "You want to deposit your capital",
      secondary:
        "You have already started the process of creating your company",
        routeToStep: 2
    },
    {
      icon: NoteIcon,
      primary: "You want to create your company",
      secondary: "Create your ApS, A/S, EMV, auto-entreprise...",
      routeToStep: 3
    },
    {
      icon: InviteIcon,
      primary: "Your business partner invited you",
      secondary: "Complete the opening of your company's account",
    },
  ];

  return (
    <Box sx={{ display: "flex", minHeight: "100%" }}>
      <Box
        sx={{
          width: "300px",
          px: "18px",
          pt: "65px",
          pb: "50px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <List sx={{ width: "100%" }} className="stepsList">
          {steps.map(({ label, step }) => (
            <ListItem key={step} onClick={()=> setActiveStep(step)} sx={{cursor: 'pointer'}}>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {`Step ${step}`}
                    {activeStep > step && (
                      <Chip
                        label="Completed"
                        sx={{
                          ml: 2,
                          backgroundColor: "#23ffc6",
                          color: "#000",
                          fontSize: 8,
                          height: "16px",
                        }}
                      />
                    )}
                  </Box>
                }
                secondary={label}
                sx={{
                  ".MuiListItemText-primary": {
                    color: activeStep === step ? "#000" : "#c6c6c6",
                  },
                  ".MuiListItemText-secondary": {
                    color: activeStep === step ? "#000" : "#c6c6c6",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
        <Box>
          <List sx={{ width: "100%", mb: "20px" }} className="onbordingList">
            <ListItem>
              <ListItemAvatar>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                    backgroundColor: "#a2ffca",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={InviteIcon} alt="not found" width={"75%"} />
                </Box>
              </ListItemAvatar>

              <ListItemText
                primary="58 27 00 45 3"
                secondary={
                  <React.Fragment>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      sx={{ fontWeight: 500 }}
                    >
                      Contact our team if you have any questions.
                    </Typography>
                    {"Mon. to Fri. 9am to 12pm and 1.30pm to 5 pm."}
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
          <Link to="#" underline="none">
            <img src={Logo} alt="logo" width={200} height={50} />
          </Link>
        </Box>
      </Box>
      <Box sx={{ flex: 1, py: "65px", backgroundColor: "#f8f8f8" }}>
        {activeStep === 0 && (
          <>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h5" sx={{ fontWeight: 400 }}>
                Already have an account?{" "}
                <Link
                  to="#"
                  underline="always"
                  sx={{ fontWeight: 700 }}
                  color="inherit"
                >
                  Sign in
                </Link>
              </Typography>
              <Typography
                variant="h1"
                component="h1"
                sx={{ fontWeight: 400, fontSize: 35 }}
              >
                What is your current situation?
              </Typography>
            </Box>
            <Box>
              <List
                sx={{ width: "100%", maxWidth: "420px", margin: "40px auto" }}
                className="onbordingList"
              >
                {onboardingSteps.slice(0, 3).map((step, index) => (
                  <ListItem key={index} secondaryAction={
                    <IconButton edge="end" onClick={()=> setActiveStep(step.routeToStep)}>
                      <EastRounded sx={{ color: "#000" }} />
                    </IconButton>
                  }>
                    <ListItemAvatar>
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "5px",
                          backgroundColor: "#a2ffca",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={step.icon} alt="not found" width={"75%"} />
                      </Box>
                    </ListItemAvatar>
                    <ListItemText
                      primary={step.primary}
                      secondary={step.secondary}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box>
              <List
                sx={{ width: "100%", maxWidth: "420px", margin: "0 auto" }}
                className="onbordingList"
              >
                <Typography
                  variant="subtitle2"
                  component="h6"
                  sx={{ fontWeight: 700, mb: "10px" }}
                >
                  Are you a beneficial owner?
                </Typography>
                <ListItem secondaryAction={
                  <IconButton edge="end">
                    <EastRounded sx={{ color: "#000" }} />
                  </IconButton>
                }>
                  <ListItemAvatar>
                    <Box
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "5px",
                        backgroundColor: "#a2ffca",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={InviteIcon} alt="not found" width={"75%"} />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={onboardingSteps[3].primary}
                    secondary={onboardingSteps[3].secondary}
                  />
                </ListItem>
              </List>
            </Box>
          </>
        )}
        {steps.map(({ step, component }) =>
          activeStep === step ? component : null
        )}
      </Box>
      <Box
        sx={{
          width: "300px",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <img
            src={YoungWomanImg}
            alt="img not found"
            width={"100%"}
            style={{
              objectFit: "cover",
              borderRadius: "0 0 50% 0",
              objectPosition: "center",
            }}
          />
        </Box>
        <Box sx={{ p: "30px 20px 50px" }}>
          <Typography
            variant="h1"
            component="h1"
            sx={{ fontWeight: 400, fontSize: 35, textAlign: "center" }}
          >
            more than 10,000 companies have adopted Pemmo
          </Typography>
          <Box sx={{ textAlign: "center", mt: "30px", mb: "20px" }}>
            <img src={StarsImg} alt="Stars img" width={"95%"} />
            <img src={RatingImg} alt="rating img" width={"90%"} />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <Link
              to="#"
              underline="none"
              sx={{
                maxWidth: {
                  xs: "120px",
                  lg: "100px",
                  xl: "120px",
                },
              }}
            >
              <img src={AppleStore} alt="apple store" width={"100%"} />
            </Link>
            <Link
              to="#"
              underline="none"
              sx={{
                maxWidth: {
                  xs: "120px",
                  lg: "100px",
                  xl: "120px",
                },
              }}
            >
              <img src={GoogleStore} alt="google store" width={"100%"} />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnBoarding;
