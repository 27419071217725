import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import Logo from "../../assets/images/symbol.svg";
import SelectionOffersCard from "../../common/selection-offers-card";
import TranferCard from "../../common/transfer-card";

const PayBill = () => {
  const recipients = [
    { name: "Telia", logo: Logo },
    { name: "Apple store", logo: Logo },
    { name: "Office rent", logo: Logo },
    { name: "Google ads", logo: Logo },
    { name: "Spotify", logo: Logo },
    { name: "Salary", logo: Logo },
  ];

  return (
    <>
      <Box sx={{ py: "50px" }}>
        <Grid
          container
          columnSpacing={{ md: "52px", lg: "35px", xl: "56px" }}
          rowSpacing={{ xs: "46px", md: "0" }}
          columns={24}
        >
          <Grid item md={12} lg={13} xl={14} xxxl={15} bs={16}>
            <TranferCard />
          </Grid>
          <Grid item md={12} lg={11} xl={10} xxxl={9} bs={8}>
            <SelectionOffersCard offers={recipients} title="vendor">
              <Typography
                variant="subtitle2"
                component="p"
                sx={{ maxWidth: "200px" }}
              >
                Select a <strong>vendor</strong> you want to transfer money to
                or pay a bill to
              </Typography>
            </SelectionOffersCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PayBill;
