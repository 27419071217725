import {
  Box,
  Button,
  ButtonGroup,
  Typography
} from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

const UsdToEurChart = () => {
  const [duration, setDuration] = useState("1H");
  const [exchangeRate, setExchangeRate] = useState(0.957397);
  const [date, setDate] = useState("Jul 4, 2022, 14:03 UTC");
  const [chartData, setChartData] = useState({
    labels: ["12:51 AM", "5:01 AM", "9:12 AM", "1:22 PM", "5:33 PM"],
    datasets: [
      {
        label: "USD to EUR",
        data: [0.95, 0.96, 0.952, 0.95, 0.955],
        borderColor: "#0a38f0",
        borderWidth: 2,
        tension: 0,
        pointRadius: 7,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart render
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, "#eef1f1");
          gradient.addColorStop(1, "#d3dff6");
          return gradient;
        },
        fill: true,
      },
    ],
  });

  const durations = ["1H", "24H", "1W", "1M", "3M", "1Y", "2Y", "5Y", "10Y"];

  const handleDurationChange = (newDuration) => {
    setDuration(newDuration);
    // Update the exchange rate and date directly for example purposes
    setExchangeRate((Math.random() * (1.1 - 0.9) + 0.9).toFixed(6));
    setDate(new Date().toLocaleString());
  };

  const options = {
    scales: {
      y: {
        display: false, // Hide the y-axis labels
      },
      x: {
        ticks: {
          color: "#000", // Customize x-axis labels color
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#ebf0f0",
        titleColor: "#000",
        bodyColor: "#000",
        boxShadow: "0 8px 15px 0 #98A9BC",
      },
    },
    elements: {
      point: {
        radius: 5,
        backgroundColor: "#3b82f6",
      },
    },
  };

  return (
    <>
      <Box flexGrow={1}>
        <ButtonGroup
          variant="contained"
          sx={{
            mb: "32px",
            boxShadow: "none",
            ".MuiButtonGroup-middleButton": {
              border: 0,
            },
            ".MuiButtonGroup-firstButton": {
              border: 0,
            },
          }}
        >
          {durations.map((dur) => (
            <Button
              key={dur}
              onClick={() => handleDurationChange(dur)}
              sx={{
                minWidth: 42,
                width: 42,
                height: 36,
                p: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: duration === dur ? "" : "transparent",
                color: duration === dur ? "#fff" : "#000",
                fontSize: 12,
                border: "none",
                fontWeight: 700,
                ":hover": {
                  color: "#fff",
                },
              }}
            >
              {dur}
            </Button>
          ))}
        </ButtonGroup>
        <Box sx={{ mb: "70px" }}>
          <Typography variant="body2" component="p" sx={{ mb: 1 }}>
            {date}
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            1 USD = {exchangeRate} EUR
          </Typography>
        </Box>
      </Box>
      <Box sx={{ transform: "scale(1.05)" }}>
        <Line data={chartData} options={options} />
      </Box>
    </>
  );
};

export default UsdToEurChart;
