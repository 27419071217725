import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DownloadTransactionsModel({
  open,
  setOpen,
  transactionsData,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fileType, setFileType] = useState("pdf");

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    if (!transactionsData) {
      console.error("Transactions data is undefined");
      return;
    }

    const filteredData = transactionsData.filter((transaction) => {
      const transactionDate = new Date(
        transaction.date.split("/").reverse().join("-")
      );
      return transactionDate >= startDate && transactionDate <= endDate;
    });

    if (fileType === "pdf") {
      downloadPDF(filteredData);
    } else if (fileType === "csv") {
      downloadCSV(filteredData);
    }
    handleClose();
  };

  const downloadCSV = (data) => {
    const csvRows = [
      ["Date", "Amount", "PaymentID", "Status"],
      ...data.map((transaction) => [
        transaction.date,
        transaction.amount,
        transaction.paymentID,
        transaction.status,
      ]),
    ];

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "transactions.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadPDF = (data) => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["Date", "Amount", "PaymentID", "Status"]],
      body: data.map((transaction) => [
        transaction.date,
        transaction.amount,
        transaction.paymentID,
        transaction.status,
      ]),
    });
    doc.save("transactions.pdf");
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent sx={{ padding: 0, maxWidth: 288 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #8d8d8d",
            p: "13px",
          }}
        >
          <Box sx={{ marginRight: 2 }}>
            <Typography
              variant="subtitle2"
              component="label"
              sx={{ fontSize: 10 }}
            >
              Date from (mm/dd/yyyy)
            </Typography>
            <TextField
              value={startDate ? startDate.toLocaleDateString() : ""}
              fullWidth
              onClick={(e) => e.preventDefault()}
              className="noBorderInput"
              placeholder="mm/dd/yyyy"
              sx={{ height: 30 }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayRoundedIcon sx={{ fontSize: "18px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              component="label"
              sx={{ fontSize: 10 }}
            >
              Date to (mm/dd/yyyy)
            </Typography>
            <TextField
              value={endDate ? endDate.toLocaleDateString() : ""}
              fullWidth
              onClick={(e) => e.preventDefault()}
              className="noBorderInput"
              placeholder="mm/dd/yyyy"
              sx={{ height: 30 }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayRoundedIcon sx={{ fontSize: "18px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box sx={{ py: "10px" }}>
          <DatePicker
            selected={startDate}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </Box>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          sx={{ backgroundColor: "#d9d9d9", padding: "6px 10px" }}
          value={fileType}
          onChange={(e) => setFileType(e.target.value)}
        >
          <FormControlLabel
            value="pdf"
            control={<Radio size="small" />}
            label="PDF"
          />
          <FormControlLabel
            value="csv"
            control={<Radio size="small" />}
            label="CSV"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button
          onClick={handleDownload}
          variant="contained"
          sx={{ width: "100%", borderRadius: 0 }}
        >
          Download
          <DownloadRoundedIcon sx={{ fontSize: "25px" }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
