import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      sx={{
        py: "20px",
        px: {
          xs: "25px",
          md: "50px",
          lg: "40px",
          xl: "70px",
          xxl: "117px",
        },
        display: "flex",
        justifyContent: { xs: "center", md: "space-between" },
        textAlign: { xs: "center", md: "start" },
        alignItems: "center",
        flexWrap: { xs: "wrap", md: "nowrap" },
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{ width: { xs: "100%", md: "unset" }, order: { xs: 2, md: 1 } }}
      >
        © Pemmo Financial Services Ltd. 2024 All rights reserved
      </Typography>
      <List
        sx={{ display: "flex", justifyContent: "center", p: 0 }}
        className="footerList"
      >
        <ListItem sx={{ width: "auto", p: 0, mx: 1 }}>
          <ListItemText>
            <Link to="#" style={{ color: "#000000" }}>
              Privacy Policy
            </Link>
          </ListItemText>
        </ListItem>

        <ListItem sx={{ width: "auto", p: 0, mx: 1 }}>
          <ListItemText>
            <Link to="#" style={{ color: "#000000" }}>
              Terms & Conditions
            </Link>
          </ListItemText>
        </ListItem>

        <ListItem sx={{ width: "auto", p: 0, mx: 1 }}>
          <ListItemText>
            <Link to="#" style={{ color: "#000000" }}>
              Help Center
            </Link>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};

export default Footer;
