import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xl: 1200,
      xxl: 1200,
      xxxl: 1400,
      bs: 1500,
    },
  },
  palette: {
    primary: {
      main: "#0a38f0",
      contrastText: "#ffffff",
    },
    success: {
      main: "#23ffc6",
    },
    error: {
      main: "#F00000",
    },
    secondary: {
      main: "#f4f4f4",
    },
    warning: {
      main: "#ffde95",
    },
  },
  shadows: {
    1: "6px 6px 6px 0px #00000040",
  },
  typography: {
    fontFamily: '"IBM Plex Sans", sans-serif',
    color: "#000000",
    h1: {
      fontSize: 42,
      fontWeight: 700,
      lineHeight: "54.6px",
      "@media (max-width:1300px)": {
        fontSize: 35,
        lineHeight: "44.6px",
      },
      "@media (max-width:576px)": {
        fontSize: 30,
        lineHeight: "34.6px",
      },
    },
    h2: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: "20px",
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: "28px",
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0,
      "@media (max-width:1200px)": {
        fontSize: 14,
        lineHeight: "21px",
      },
    },

    h6: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "21px",
      "@media (max-width:576px)": {
        fontSize: 12,
        lineHeight: "18px",
      },
    },
    body1: {
      fontSize: 20,
      fontWeight: 300,
      lineHeight: "21px",
      "@media (max-width:1300px)": {
        fontSize: 18,
      },
      "@media (max-width:1200px)": {
        fontSize: 16,
      },
      "@media (max-width:1024px)": {
        fontSize: 14,
      },
      "@media (max-width:576px)": {
        fontSize: 12,
        lineHeight: "18px",
      },
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "21px",
      color: "#000000",
      "@media (max-width:576px)": {
        fontSize: 12,
        lineHeight: "18px",
      },
    },
    subtitle1: {
      fontSize: 10,
      fontWeight: 400,
      color: "#000000",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "18px",
      color: "#000000",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "unset",
          padding: 0,
          "@media (max-width:1024px)": {
            backgroundColor: "#f4f4f4",
            borderBottom: "1px solid #e0e0e0",
            borderRadius: 0,
          },
          ".MuiToolbar-root": {
            minHeight: 0,
            padding: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          border: "1px solid transparent",
          padding: "15px 16px",
          fontSize: 14,
          fontWeight: 400,
          letterSpacing: "0.16px",
          lineHeight: "18px",
          textTransform: "unset",
          borderRight: 5,
          "@media (max-width:400px)": {
            fontSize: 12,
          },
          "&:focus": {
            borderColor: "#0f62fe",
            boxShadow: "inset 0 0 0 1px #0f62fe, inset 0 0 0 2px white",
          },
          "&:active": {
            // backgroundColor: "#0056b3",
            boxShadow: "inset 0 0 0 1px #0f62fe, inset 0 0 0 2px white",
          },
          "&.blackBtn": {
            fontWeight: 700,
            border: "2px solid #000",
            color: "#000",
            justifyContent: "center",
            "&:focus": {
              borderColor: "#0f62fe",
              boxShadow: "none",
              border: "2px solid #000",
            },
            "&:active": {
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "2px solid #000",
            },
          },
          "&.blackOutlinedBtn": {
            fontWeight: 400,
            justifyContent: "space-between",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
          "&.sidebarList": {
            ".MuiListItemButton-root ": {
              padding: "7px 16px",
              marginBottom: "5px",
              ".MuiListItemText-root": {
                margin: 0,
              },
              ".MuiTypography-root": {
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: "0.16px",
                lineHeight: "18px",
              },
            },
            ".MuiListItemButton-root.active": {
              ".MuiTypography-root": {
                fontWeight: 700,
              },
            },
            ".innerListSidebar": {
              ".MuiListItemButton-root ": {
                padding: "10px 20px",
                ".MuiTypography-root": {
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0,
                },
                ".MuiListItemIcon-root": {
                  minWidth: "20px",
                },
              },
            },
          },
          "&.navbarList": {
            ".MuiListItemButton-root": {
              padding: "12px 16px",
              "@media (max-width:1200px)": {
                padding: "12px 8px",
              },
            },
            ".MuiTypography-root": {
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: "0.16px",
              lineHeight: "18px",
            },
          },
          "&.footerList": {
            ".MuiTypography-root": {
              fontSize: 12,
              fontWeight: 400,
              letterSpacing: "0.16px",
              lineHeight: "18px",
            },
          },
          "&.accountDetailsList": {
            backgroundColor: "#F4F4F4",
            boxShadow: "6px 6px 6px 0px #00000040",
            padding: "13px 18px",
            ".MuiListItem-root": {
              padding: "19px 0 11px 0",
              borderBottom: "1px solid #E2E9E6",
            },
          },
          "&.onbordingList": {
            ".MuiListItem-root": {
              backgroundColor: "#fff",
              padding: "17px 40px 10px 15px",
              borderBottom: "3px solid #000",
              alignItems: "start",
            },
            ".MuiListItemText-root": {
              margin: "0",
            },
            ".MuiListItemText-primary ": {
              fontSize: 14,
              fontWeight: 700,
            },
            ".MuiListItemText-secondary": {
              fontSize: 12,
              fontWeight: 400,
              lineHeight: "16px",
            },
            ".MuiListItemSecondaryAction-root": {
              right: "30px",
            },
            ".MuiListItemAvatar-root": {
              minWidth: "45px",
            },
          },
          "&.stepsList": {
            ".MuiListItem-root": {
              padding: "10px",
              marginBottom: "20px",
            },
            ".MuiListItemText-root": {
              margin: "0",
            },
            ".MuiListItemText-primary ": {
              fontSize: 20,
              fontWeight: 700,
              marginBottom: "10px",
            },
            ".MuiListItemText-secondary": {
              fontSize: 16,
              fontWeight: 500,
            },
          },
          "&.linksList": {
            display: "flex",
            justifyContent: "center",
            ".MuiListItem-root": {
              padding: "0",
              width: "52px",
              height: "48px",
              border: "1px solid #000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 10px",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid #4285F4",
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          boxShadow:
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#f4f4f4",
          boxShadow: "6px 6px 6px 0px #00000040",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "16px",
          height: "100%",
          borderRadius: 5,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&.sidebarSelect": {
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "0.16px",
            lineHeight: "18px",
            color: "#fff",
            ".MuiSelect-select": {
              padding: "15px 16px",
              backgroundColor: "#0a38f0",
              border: 0,
              borderRadius: 0,
            },
            ".MuiSelect-icon": {
              color: "#fff",
            },
            ".MuiList-root": {
              padding: 0,
            },
          },
          "&.commonSelect": {
            height: 48,
            backgroundColor: "#fff",
            width: "100%",
            ".MuiSelect-select": {
              padding: "15px 16px !important",
              borderRadius: 0,
              fontSize: 14,
              fontWeight: 400,
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "2px solid #0a38f0",
              borderRadius: 0,
            },
            ".MuiSelect-icon": {
              color: "#000",
            },
          },
          "&.paginationSelect": {
            height: 24,
            borderRadius: 0,
            ".MuiSelect-select": {
              padding: "2px 40px 2px 30px",
              borderRadius: 0,
              fontSize: 14,
              fontWeight: 400,
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: 0,
              borderRadius: 0,
            },
            ".MuiSelect-icon": {
              color: "#000",
            },
          },
          "&.noBorderSelect": {
            height: 40,
            backgroundColor: "#fff",
            width: "100%",
            borderRadius: 0,
            ".MuiSelect-select": {
              padding: "15px 16px !important",
              borderRadius: 0,
              fontSize: 14,
              fontWeight: 400,
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderRadius: 0,
            },
            ".MuiSelect-icon": {
              color: "#000",
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          ".MuiTableHead-root": {
            backgroundColor: "#f4f4f4",
            ".MuiTableCell-root": {
              fontWeight: 600,
              padding: "15px 16px",
            },
          },
          ".MuiTableCell-root": {
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "0.16px",
            lineHeight: "18px",
            color: "#000000",
            padding: "11px 16px",
            borderColor: "#000000",
            "@media (max-width:768px)": {
              fontSize: 12,
            },
          },
          ".MuiTablePagination-toolbar": {
            minHeight: "unset",
            padding: 0,
            width: "100%",
            ".MuiInputBase-input": {
              padding: "unset",
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: "0.16px",
              lineHeight: "18px",
              color: "#000000",
              backgroundColor: "transparent",
              "@media (max-width:768px)": {
                fontSize: 12,
              },
            },
            "&.MuiTablePagination-select": {
              margin: "16px !important",
            },
            ".MuiIconButton-root": {
              padding: 0,
              width: 32,
              height: 32,
              color: "#000000",
              border: "1px solid transparent",
              borderLeftColor: "#c6c6c6",
              borderRadius: 0,
            },
            ".MuiTablePagination-displayedRows": {
              marginRight: 16,
            },
          },
          "&.ListAccountsTable": {
            ".MuiTableCell-root": {
              borderColor: "transparent",
              backgroundColor: "#f4f4f4",
              padding: "17px 16px",
              height: 81,
              border: 0,
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "&.transactionsMenu": {
            ".MuiMenuItem-root": {
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: "0.16px",
              lineHeight: "18px",
              color: "#0012F0",
              padding: "11px 15px",
              "&:hover": {
                backgroundColor: "#0012F0",
                color: "#ffffff",
              },
            },
          },
          "&.commonSelectMenu": {
            backgroundColor: "red",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: 0,
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:focus": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          "&:active": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          ".MuiInputBase-root": {
            fontSize: 14,
            fontWeight: 400,
            color: "#000000",
            height: 48,
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "2px solid #0a38f0",
            borderRadius: 0,
            "&:hover": {
              border: "2px solid #0a38f0",
            },
          },
          ".MuiInputAdornment-root": {
            ".MuiTypography-root": {
              fontSize: 14,
              fontWeight: 800,
              color: "#000000",
            },
          },

          ".MuiInputBase-input": {
            "&::placeholder": {
              fontSize: 14,
              fontWeight: 400,
              color: "#000000",
            },
          },
          "&.tableSearchInput": {
            ".MuiOutlinedInput-notchedOutline": {
              border: 0,
              borderRadius: 0,
            },
            ".MuiInputBase-input": {
              "&::placeholder": {
                fontSize: 14,
                fontWeight: 400,
                color: "#000000",
              },
            },
          },
          "&.noBorderInput": {
            ".MuiOutlinedInput-notchedOutline": {
              border: 0,
              borderRadius: 0,
            },
            ".MuiInputBase-input": {
              padding: 0,
            },
          },
          "&.incrementInput": {
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "&.OPTInput": {
            backgroundColor: "#fff",
            ".MuiInputBase-root": {
              fontSize: 30,
              fontWeight: 700,
              color: "#000000",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "3px solid #000",
              "&:hover": {
                border: "3px solid #000",
              },
              "&:focus": {
                border: "3px solid #000",
              },
            },
          },
          "&.authInput": {
            position: "relative",
            ".MuiInputBase-root": {
              height: 40,
              position: "relative",
              "&::after": {
                content: '" "',
                width: "100%",
                position: "absolute",
                bottom: "-3px",
                left: 0,
                right: 0,
                height: "3px",
                backgroundColor: "#000",
              },
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiInputAdornment-root": {
              ".MuiTypography-root": {
                fontSize: 14,
                fontWeight: 800,
                color: "#000000",
              },
            },

            ".MuiInputBase-input": {
              "&::placeholder": {
                fontSize: 14,
                fontWeight: 400,
                color: "#000000",
              },
            },
          },
          "&.blackBorderInput": {
            ".MuiInputBase-root": {
              height: 40,
              backgroundColor: "#f5f5f5",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "2px solid #000",
            },
            ".MuiInputAdornment-root": {
              ".MuiTypography-root": {
                fontSize: 14,
                fontWeight: 800,
                color: "#000000",
              },
              "&::placeholder": {
                fontSize: 14,
                fontWeight: 400,
                color: "#000",
              },
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: "100%",
          ".MuiTabs-root": {
            width: "100%",
          },
          ".MuiButtonBase-root": {
            padding: "14px 16px",
            textTransform: "unset",
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: "0.16px",
            color: "#000000",
            "&:focus": {
              color: "#000000",
            },
            "&:active": {
              color: "#000000",
            },
          },
          ".MuiButtonBase-root.Mui-selected": {
            color: "#000000",
          },
          ".MuiTabs-indicator": {
            color: "#000000",
            backgroundColor: "#000000",
          },
          ".MuiTabScrollButton-root": {
            padding: 0,
            width: 30,
          },
          ".MuiTabScrollButton-root:first-of-type": {
            justifyContent: "start",
          },
          ".MuiTabScrollButton-root:last-of-type": {
            justifyContent: "end",
          },
          ".MuiTabScrollButton-root.Mui-disabled": {
            display: "none",
          },
          "@media (min-width:768px)": {
            backgroundColor: "#eef1f1",
            borderRadius: "5px",
            overflow: "hidden",
            ".MuiButtonBase-root": {
              color: "#000000",
              border: "1px solid transparent",
              backgroundColor: "#eef1f1",
              "&:focus": {
                borderColor: "#0f62fe",
                boxShadow: "inset 0 0 0 1px #0f62fe, inset 0 0 0 2px white",
                color: "#fff",
              },
              "&:active": {
                backgroundColor: "#0056b3",
                boxShadow: "inset 0 0 0 1px #0f62fe, inset 0 0 0 2px white",
                color: "#fff !important",
                borderRadius: "5px",
              },
            },
            ".MuiButtonBase-root.Mui-selected": {
              backgroundColor: "#0a38f0",
              color: "#fff",
              // fontWeight: 700,
              borderRadius: "5px",
            },
            ".MuiTabs-indicator": {
              display: "none",
            },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "unset",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          alignItems: "start",
          ".MuiCheckbox-root": {
            padding: 0,
            marginRight: "8px",
          },
          ".MuiTypography-root": {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "21px",
            color: "#000000",
          },
          "&.authFromControl": {
            ".MuiTypography-root": {
              color: "#414143",
              lineHeight: "18px",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow:
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
          fontSize: 12,
          fontWeight: 400,
          lineHeight: "18px",
          color: "#000000",
          padding: "8px 16px",
          textAlign: "center",
        },
        arrow: {
          color: "#fff",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(2px)",
          ".MuiDialogActions-root>:not(style)~:not(style)": {
            margin: 0,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#000",
          cursor: "pointer",
          textDecorationColor: "#000",
          "&.blueLink": {
            color: "#0012F0",
            textDecorationColor: "#0012F0",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.authAccordion": {
            margin: "0",
            boxShadow: "none",
            borderBottom: "3px solid #000",
            borderRadius: 0,
            ".MuiAccordionSummary-root": {
              padding: "0 8px 0 15px",
            },
            ".MuiAccordionSummary-content": {
              margin: "13px 0 24px",
            },
          },
        },
      },
    },
  },
});

export default theme;
