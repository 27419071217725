import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import MakePayment from "./make-payment";
import PayBill from "./pay-bill";
import Textport from "./tex-port";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Payments = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            "@media (min-width:768px)": {
              boxShadow: "6px 6px 6px 0px #00000040",
              maxWidth: "fit-content",
              borderRadius: "5px",
            },
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Payment" {...a11yProps(0)} />
            <Tab label="PayBill" {...a11yProps(1)} />
            <Tab label="Tax pots" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <MakePayment />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <PayBill />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <Textport />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Payments;
