import {
  CheckCircleRounded,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";

import AppleIcon from "../../../assets/icons/Apple.svg";
import FacebookIcon from "../../../assets/icons/Facebook.svg";
import GoogleIcon from "../../../assets/icons/Google.svg";
import LinkedinIcon from "../../../assets/icons/LinkedIn.svg";

// Validation schema
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  phoneNumber: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase character")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    ),
});

const passwordCriteria = [
  { label: "One lowercase character", regex: /[a-z]/ },
  { label: "One number", regex: /\d/ },
  { label: "One uppercase character", regex: /[A-Z]/ },
  { label: "One special character", regex: /[!@#$%^&*(),.?":{}|<>]/ },
  { label: "8 characters minimum", check: (value) => value.length >= 8 },
];

const socialIcons = [
  { src: AppleIcon, alt: "Apple", width: 20 },
  { src: GoogleIcon, alt: "Google", width: 20 },
  { src: LinkedinIcon, alt: "LinkedIn", width: 20 },
  { src: FacebookIcon, alt: "Facebook", width: 20 },
];

const StepOne = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const formikConfig = {
    initialValues: {
      email: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("Login data:", values);
      resetForm();
    },
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h1" component="h1" sx={{ fontSize: 32 }}>
          Get pre-approved in 5 minutes
        </Typography>
        <Typography variant="h5" component="p" sx={{ fontWeight: 400 }}>
          Apply for your business account, and get started once you approved
        </Typography>
      </Box>

      <Box sx={{ width: "100%", maxWidth: "420px", margin: "30px auto" }}>
        <Formik {...formikConfig}>
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="mt-[30px] w-full">
              <FormControl fullWidth sx={{ mb: "30px" }}>
                <Typography
                  id="email"
                  variant="subtitle2"
                  component="label"
                  sx={{ mb: "4px", fontWeight: 700 }}
                >
                  Work email*
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  placeholder="Enter email"
                  variant="outlined"
                  className="authInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "red", mt: "4px" }}
                  >
                    {errors.email}
                  </Typography>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mb: "30px" }}>
                <Typography
                  id="phone"
                  variant="subtitle2"
                  component="label"
                  sx={{ mb: "4px", fontWeight: 700 }}
                >
                  Phone number*
                </Typography>
                <PhoneInput
                  country={"us"}
                  value={values.phoneNumber}
                  onChange={(value) => setFieldValue("phoneNumber", value)}
                  onBlur={handleBlur("phoneNumber")}
                  inputStyle={{
                    width: "100%",
                    height: 30,
                    border: "2px solid transparent",
                    backgroundColor: "#fff",
                    borderRadius: "0",
                    paddingLeft: 55,
                  }}
                  buttonStyle={{
                    height: 30,
                    border: "2px solid transparent",
                    borderRightColor: "#000",
                    backgroundColor: "#fff",
                    borderRadius: "2px",
                    width: 50,
                    top: "5px",
                    padding: "0px",
                  }}
                  containerStyle={{
                    padding: "5px",
                    borderBottom: "3px solid #000",
                    backgroundColor: "#fff",
                  }}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "red", mt: "4px" }}
                  >
                    {errors.phoneNumber}
                  </Typography>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mb: "30px" }}>
                <Typography
                  id="password"
                  variant="subtitle2"
                  component="label"
                  sx={{ mb: "4px", fontWeight: 700 }}
                >
                  Password*
                </Typography>
                <TextField
                  fullWidth
                  id="password"
                  variant="outlined"
                  className="authInput"
                  placeholder="******"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlined sx={{ color: "#000" }} />
                          ) : (
                            <VisibilityOutlined sx={{ color: "#000" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && touched.password && (
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "red", mt: "4px" }}
                  >
                    {errors.password}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                    mt: "18px",
                  }}
                >
                  {passwordCriteria.map((criteria, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      sx={{
                        color: criteria.regex
                          ? criteria.regex.test(values.password)
                            ? "#12AA5B"
                            : "#a7a7a8"
                          : criteria.check(values.password)
                          ? "#12AA5B"
                          : "#a7a7a8",
                        display: "flex",
                        alignItems: "center",
                        width: "48%",
                      }}
                    >
                      <CheckCircleRounded
                        sx={{ fontSize: 18, mr: "8px" }}
                      />{" "}
                      {criteria.label}
                    </Typography>
                  ))}
                </Box>
              </FormControl>

              <FormControlLabel
                control={<Checkbox />}
                label="Please exclude me from any future emails regarding Triosale and related Intuit product and feature updates, marketing best practices, and promotions."
                className="authFromControl"
              />

              <Typography variant="body2" sx={{ mt: "15px", color: "#414143" }}>
                By registering for an account, you are consenting to our{" "}
                <Link href="#" underline="always" className="blueLink">
                  Terms of Service
                </Link>{" "}
                and confirming that you have reviewed and accepted the{" "}
                <Link href="#" underline="always" className="blueLink">
                  Global Privacy Statement
                </Link>
                .
              </Typography>

              <Button
                variant="contained"
                color="success"
                fullWidth
                sx={{
                  height: 64,
                  alignItems: "start",
                  borderRadius: 0,
                  my: "30px",
                }}
              >
                Create account
              </Button>
            </Form>
          )}
        </Formik>
        <List className="linksList">
          {socialIcons.map((icon, index) => (
            <ListItem key={index}>
              <IconButton>
                <img src={icon.src} alt={icon.alt} width={icon.width} />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Typography
          variant="h5"
          sx={{ fontWeight: 400, mt: "50px", textAlign: "center" }}
        >
          Already have an account?{" "}
          <Link
            to="#"
            underline="always"
            sx={{ fontWeight: 700 }}
            color="inherit"
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default StepOne;
