import ClearIcon from "@mui/icons-material/Clear";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import Logo from "../../assets/images/symbol.svg";

const TranferCard = () => {
  const [allRecepientsModel, setAllRecepientsModel] = useState(false);
  const [account, setAccount] = useState("");
  const [company, setCompany] = useState("");
  const [amount, setAmount] = useState("");
  const [startText, setStartText] = useState("");
  const [fee, setFee] = useState(0);

  const accounts = [
    {
      value: "eu",
      label: "EUR8900 99876 77767 88788",
      startText: "EUR",
      fee: 2.5,
    },
    {
      value: "gb",
      label: "UK8900 99876 77767 88788",
      startText: "GBP",
      fee: 2,
    },
    {
      value: "dk",
      label: "DK8900 99876 77767 88788",
      startText: "DKK",
      fee: 3,
    },
  ];

  const companies = [
    { value: "Company inc.", label: "Company inc." },
    { value: "Pemmo Ltd.", label: "Pemmo Ltd." },
  ];

  const handleAccountChange = (event) => {
    const selectedAccount = accounts.find(
      (acc) => acc.value === event.target.value
    );
    setAccount(event.target.value);
    setStartText(selectedAccount.startText);
    setFee(selectedAccount.fee);
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleClearAccount = () => {
    setAccount("");
    setStartText("");
    setFee(0);
  };

  const handleClearCompany = () => {
    setCompany("");
  };
  const recipients = [
    { name: "Telia", logo: Logo },
    { name: "Apple store", logo: Logo },
    { name: "Office rent", logo: Logo },
    { name: "Google ads", logo: Logo },
    { name: "Spotify", logo: Logo },
    { name: "Salary", logo: Logo },
  ];

  const getFlag = (countryCode) => {
    switch (countryCode) {
      case "eu":
        return (
          <Avatar sx={{ width: 25, height: 25 }}>
            <span
              className={`fi fi-eu`}
              style={{ transform: "scale(1.5)" }}
            ></span>
          </Avatar>
        );
      case "gb":
        return (
          <Avatar sx={{ width: 25, height: 25 }}>
            <span
              className={`fi fi-gb`}
              style={{ transform: "scale(1.5)" }}
            ></span>
          </Avatar>
        );
      case "dk":
        return (
          <Avatar sx={{ width: 25, height: 25 }}>
            <span
              className={`fi fi-dk`}
              style={{ transform: "scale(1.5)" }}
            ></span>
          </Avatar>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Card
        sx={{
          backgroundColor: "#eef1f1",
          p: 0,
          justifyContent: "space-between",
        }}
      >
        <CardContent
          sx={{
            p: { xs: "36px 20px", xl: "36px 64px", xxxl: "36px 152px 64px 64px" },
            width: "100%",
          }}
        >
          <Typography variant="h2" component="h2" sx={{ mb: "15px" }}>
            Transfer
          </Typography>
          <Typography variant="subtitle2" component="p">
            Transfers made on <strong>weekends</strong> or{" "}
            <strong>holidays</strong> take <strong>longer</strong>. All
            transfers are subject to review and could be delayed or stopped if
            we identify an issue.
          </Typography>
          <Box sx={{ mt: "26px" }}>
            <FormControl fullWidth sx={{ mb: "15px" }}>
              <Typography
                id="account"
                variant="subtitle2"
                component="label"
                sx={{ mb: "5px" }}
              >
                Select the <strong>account</strong> you want to send{" "}
                <strong>from</strong>
              </Typography>
              <Select
                labelId="account"
                id="account"
                value={account}
                onChange={handleAccountChange}
                IconComponent={ExpandMoreRoundedIcon}
                className="commonSelect"
                fullWidth
                endAdornment={
                  account && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearAccount} sx={{ mr: 3 }}>
                        <ClearIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {accounts.map((acc) => (
                  <MenuItem
                    key={acc.value}
                    value={acc.value}
                    sx={{
                      padding: "15px 16px",
                      fontSize: 14,
                      fontWeight: 400,
                      "&.MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "#eef1f1",
                        "&:hover": {
                          backgroundColor: "#eef1f1",
                        },
                      },
                    }}
                  >
                    {acc.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: "15px" }}>
              <Typography
                id="company"
                variant="subtitle2"
                component="label"
                sx={{ mb: "5px" }}
              >
                Select a recipient, the <strong>entity</strong> you want to send{" "}
                <strong>to</strong>
              </Typography>
              <Select
                labelId="company"
                id="company"
                value={company}
                onChange={handleCompanyChange}
                IconComponent={ExpandMoreRoundedIcon}
                className="commonSelect"
                fullWidth
                endAdornment={
                  company && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearCompany} sx={{ mr: 3 }}>
                        <ClearIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {companies.map((comp) => (
                  <MenuItem
                    key={comp.value}
                    value={comp.value}
                    sx={{
                      padding: "15px 16px",
                      fontSize: 14,
                      fontWeight: 400,
                      "&.MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "#eef1f1",
                        "&:hover": {
                          backgroundColor: "#eef1f1",
                        },
                      },
                    }}
                  >
                    {comp.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ position: "relative", zIndex: 999 }}>
              <FormControl fullWidth sx={{ position: "relative" }}>
                <Typography
                  id="amount"
                  variant="subtitle2"
                  component="label"
                  sx={{ mb: "5px" }}
                >
                  Select the <strong>amount</strong> you want to send to the{" "}
                  <strong>recipient</strong>
                </Typography>
                <TextField
                  id="amount"
                  variant="outlined"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {startText}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {getFlag(account)}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
            {account && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      height: 35,
                      width: 37,
                      border: "2px solid transparent",
                      borderLeftColor: "#0a38f0",
                      borderBottomColor: "#0a38f0",
                      position: "absolute",
                      left: "15px",
                      top: "-1px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      boxShadow: "6px 6px 6px 0px #00000040",
                      padding: "11px",
                      zIndex: 998,
                      textAlign: "center",
                      maxWidth: 200,
                      position: "relative",
                      ml: "50px",
                      mt: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      Transfer fee{" "}
                      <strong>
                        {fee} {startText}
                      </strong>
                    </Typography>
                  </Box>
                  <Tooltip
                    title="This fee covers our salaries and the maintenance of the software's functionality and related services."
                    arrow
                  >
                    <HelpOutlineRoundedIcon
                      sx={{ mt: "18px", ml: "16px", fontSize: "22px" }}
                    />
                  </Tooltip>
                </Box>
              </>
            )}
          </Box>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50%",
              height: 64,
              alignItems: "start",
              borderRadius: 0,
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              width: "50%",
              height: 64,
              alignItems: "start",
              borderRadius: 0,
            }}
          >
            <Typography variant="body2">
              Transfer <strong>now</strong>
            </Typography>
            <EastRoundedIcon sx={{ fontSize: "20px" }} />
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default TranferCard;
