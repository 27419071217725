import React, { useState } from "react";
import {
  TableHead,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import CustomPagination from "../../components/custom-pagination";

export default function TransactionsTable({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ backgroundColor: "#eaeaea" }}>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "160px" }}>Date</TableCell>
              <TableCell sx={{ width: "160px" }}>Amount</TableCell>
              <TableCell sx={{ width: "160px" }}>PaymentID</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: "160px" }}>{row.date}</TableCell>
                <TableCell sx={{ width: "160px" }}>{row.amount}</TableCell>
                <TableCell sx={{ width: "160px" }}>{row.paymentID}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
