import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AppleIcon from "../../../assets/icons/Apple.svg";
import GoogleIcon from "../../../assets/icons/Google.svg";
import Logo from "../../../assets/images/pemmo-logo.svg";
import AuthImg from "../../../assets/images/authImg.svg";
import { EastRounded, KeyboardBackspaceRounded } from "@mui/icons-material";

const ForgotPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100%" }}>
      <Box
        sx={{
          width: {
            xs: "100%",
            md: 450,
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "45px",
        }}
      >
        <Box sx={{ mb: "50px" }}>
          <Link to="#" underline="none">
            <img src={Logo} alt="logo" width={200} height={50} />
          </Link>
        </Box>

        <Box>
          <Button
            variant="outlined"
            className="blackOutlinedBtn blackBtn"
            sx={{
              height: 30,
              width: 236,
              p: "6px 20px 6px 10px",
              borderRadius: 0,
              mb: "45px",
            }}
          >
            <KeyboardBackspaceRounded size={16} />
            Cancel password reset
          </Button>
          <Box sx={{ mb: "30px" }}>
            <Typography variant="h3" sx={{ lineHeight: "normal", mb: "10px" }}>
              Are you sure you want to reset your passcode?
            </Typography>
            <Typography variant="body2">
              You will be able to choose a new secret code. We will then ask you
              to log in using a temporary 6-digit code you will receive by text
              or email.
            </Typography>
          </Box>
          <FormControl fullWidth>
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              Your phone number
            </Typography>
            <PhoneInput
              country={"us"}
              value={phoneNumber}
              onChange={setPhoneNumber}
              inputStyle={{
                width: "100%",
                height: 30,
                border: "2px solid transparent",
                backgroundColor: "#f5f5f5",
                borderRadius: "0",
                paddingLeft: 55,
              }}
              buttonStyle={{
                height: 30,
                border: "2px solid transparent",
                borderRightColor: "#000",
                backgroundColor: "#f5f5f5",
                borderRadius: "2px",
                width: 50,
                top: "5px",
                padding: "0px",
              }}
              containerStyle={{
                padding: "5px",
                border: "2px solid #000",
                backgroundColor: "#f5f5f5",
              }}
            />
          </FormControl>
          <Typography variant="subtitle2" sx={{ fontWeight: 700, my: "15px" }}>
            or
          </Typography>
          <FormControl fullWidth>
            <Typography
              id="email"
              variant="subtitle2"
              component="label"
              sx={{ mb: "4px", fontWeight: 700 }}
            >
              Email
            </Typography>
            <TextField
              fullWidth
              id="email"
              placeholder="Enter email"
              variant="outlined"
              className="blackBorderInput"
            />
          </FormControl>
          <Button
            variant="contained"
            color="success"
            sx={{
              height: 30,
              width: 170,
              p: "6px 10px",
              borderRadius: 0,
              my: "30px",
            }}
            onClick={handleNext}
            disabled={loading}
          >
            Reset password
            {loading ? (
              <CircularProgress size={16} sx={{ color: "#000" }} />
            ) : null}
          </Button>
          <Box sx={{ mb: "30px" }}>
            <Typography variant="h3" sx={{ lineHeight: "normal", mb: "10px" }}>
              Are you sure you want to reset your passcode?
            </Typography>
            <Typography variant="body2">
              You will be able to choose a new secret code. We will then ask you
              to log in using a temporary 6-digit code you will receive by text
              or email.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "17px",
            }}
          >
            <Button
              variant="outlined"
              className="blackBtn"
              sx={{
                height: 40,
                borderRadius: 0,
              }}
            >
              <Box mr={"8px"}>
                <img src={AppleIcon} alt="apple" />
              </Box>
              Login via Apple
            </Button>
            <Button
              variant="outlined"
              className="blackBtn"
              sx={{
                height: 40,
                borderRadius: 0,
              }}
            >
              <Box mr={"8px"}>
                <img src={GoogleIcon} alt="apple" />
              </Box>
              Login via Google
            </Button>
          </Box>
        </Box>
        <List sx={{ display: "flex", p: 0, mt: "50px" }} className="footerList">
          <ListItem sx={{ width: "auto", p: 0, mr: 1 }}>
            <ListItemText>
              <Link to="#" underline="none">
                Privacy Policy
              </Link>
            </ListItemText>
          </ListItem>

          <ListItem sx={{ width: "auto", p: 0, mx: 1 }}>
            <ListItemText>
              <Link to="#" underline="none">
                Terms & Conditions
              </Link>
            </ListItemText>
          </ListItem>

          <ListItem sx={{ width: "auto", p: 0, mx: 1 }}>
            <ListItemText>
              <Link to="#" underline="none">
                Help Center
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          background: "#f8f8f8",
          width: 0,
          p: "45px",
          display: {
            xs: "none",
            md: "flex",
          },
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h1"
            component="h1"
            sx={{ textAlign: "center", fontSize: "50px" }}
          >
            Need help?
          </Typography>
          <Box sx={{ width: "50%", m: "70px auto" }}>
            <img src={AuthImg} alt="img not found" width={"100%"} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: 30,
                width: 140,
                p: "6px 15px",
                borderRadius: 0,
                fontWeight: 700,
              }}
            >
              Chat with us
              <EastRounded sx={{ fontSize: 14 }} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: 30,
                width: 140,
                p: "6px 15px",
                borderRadius: 0,
                fontWeight: 700,
              }}
            >
              Give us a call
              <EastRounded sx={{ fontSize: 14 }} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: 30,
                width: 140,
                p: "6px 15px",
                borderRadius: 0,
                fontWeight: 700,
              }}
            >
              Email us
              <EastRounded sx={{ fontSize: 14 }} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
