import { Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

const TransactionsMenu = ({ onBlock, onFreeze, onCreate, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box aria-haspopup="true" onClick={handleClick} sx={{cursor: 'pointer'}}>
        {children}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="transactionsMenu"
      >
        <MenuItem onClick={handleClose} divider>
          See card transactions
        </MenuItem>
        <MenuItem onClick={handleClose} divider>
          See card information
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onBlock();
          }}
          style={{ color: "red" }}
        >
          Block card
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onFreeze();
          }}
        >
          Freeze card
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onCreate();
          }}
        >
          + Create new card
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TransactionsMenu;
