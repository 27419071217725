import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "./components/app-layout";
import Accounts from "./pages/accounts";
import AccountDetails from "./pages/accounts/account-details";
import Cards from "./pages/cards";
import Dashboard from "./pages/dashboard";
import Transactions from "./pages/transactions";
import Payments from "./pages/payments";
import Login from "./pages/auth/login/login";
import Onboarding from "./pages/auth/on-boarding/on-boarding";
import ForgotPassword from "./pages/auth/forgot-password/forgot-password";

const SubPage = ({ title }) => (
  <div>
    <h1>{title}</h1>
  </div>
);

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/" element={<AppLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="accounts" element={<Accounts />} />
        <Route
          path="accounts/sub1"
          element={<SubPage title="Accounts Sub Page" />}
        />
        <Route
          path="accounts/account-details/:accountNumber"
          element={<AccountDetails />}
        />
        <Route path="cards" element={<Cards />} />
        <Route path="cards/sub1" element={<SubPage title="Cards Sub Page" />} />
        <Route path="transactions" element={<Transactions />} />
        <Route
          path="transactions/sub1"
          element={<SubPage title="Transactions Sub Page" />}
        />
        <Route path="payment" element={<Payments />} />
        <Route
          path="payment/sub1"
          element={<SubPage title="Payment Sub Page" />}
        />
        <Route path="invoicing" element={<h1>invoicing</h1>} />
        <Route path="apps" element={<h1>APP</h1>} />
      </Route>
    </Routes>
  );
}

export default App;
