import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AllRecepientsModel from "./all-recepients-model";

const SelectionOffersCard = ({ offers, title, children }) => {
  const [allRecepientsModel, setAllRecepientsModel] = useState(false);
  const [selectedOffers, setSelectedOffers] = useState([]);

  const handleSelect = (name) => {
    setSelectedOffers((prev) => {
      if (prev.includes(name)) {
        return prev.filter((item) => item !== name);
      } else {
        return [...prev, name];
      }
    });
  };

  return (
    <>
      <Card sx={{ backgroundColor: "#eef1f1", p: 0 }}>
        <CardContent
          sx={{ p: { xs: "36px 20px", md: "36px 26px 53px" }, width: "100%" }}
        >
          <Typography variant="h2" component="h2" sx={{ mb: "15px" }}>
            Select {title}
          </Typography>
          {children}
          <Box sx={{ overflow: "hidden" }}>
            <Grid container columns={24} spacing={"20px"} sx={{ mt: "0px" }}>
              {offers.map((item, index) => (
                <Grid item xs={12} sm={8} md={12} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt="img not found"
                      src={item.logo}
                      sx={{
                        width: 36,
                        height: 36,
                        boxShadow: "6px 6px 6px 0px #00000040",
                      }}
                    />
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ mt: "10px", mb: "5px" }}
                    >
                      {item.name}
                    </Typography>
                    <Button
                      variant={"contained"}
                      color={
                        selectedOffers.includes(item.name)
                          ? "success"
                          : "primary"
                      }
                      sx={{
                        width: "100%",
                        maxWidth: "122px",
                        px: "7px",
                        py: "4px",
                        justifyContent: "center",
                        boxShadow: "none",
                        fontSize: 10,
                        "@media (max-width:400px)": {
                          fontSize: 10,
                        },
                      }}
                      onClick={() => handleSelect(item.name)}
                    >
                      {selectedOffers.includes(item.name)
                        ? `Selected`
                        : `Choose this ${title}`}
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Button
            variant="contained"
            color="success"
            sx={{
              width: "100%",
              height: 64,
              alignItems: "start",
              borderRadius: 0,
            }}
            onClick={() => setAllRecepientsModel(true)}
          >
            <Typography variant="body2">
              See all <strong>{title}s</strong>
            </Typography>
          </Button>
        </Box>
      </Card>
      <AllRecepientsModel
        open={allRecepientsModel}
        setOpen={setAllRecepientsModel}
        title={title}
      />
    </>
  );
};

export default SelectionOffersCard;
