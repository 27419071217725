import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import {
  Clear,
  ExpandMoreRounded,
  HelpOutlineRounded,
} from "@mui/icons-material";
import ChangePerModel from "./change-percentage-model";

const Textport = () => {
  const [percentage, setpercentage] = useState("");
  const [accounts, setaccounts] = useState("");
  const [payreport, setpayreport] = useState("");
  const [changePermodel, setChangePerModel] = useState(false);

  const revenue = [
    { value: "1%", label: "1%" },
    { value: "2%", label: "2%" },
    { value: "3%", label: "3%" },
    { value: "4%", label: "4%" },
    { value: "5%", label: "5%" },
    { value: "6%", label: "6%" },
  ];
  const accountsDetails = [
    { value: "DK8900 99876 77767 88788 ", label: "DK8900 99876 77767 88788 " },
    { value: "DK8900 99876 77767 88788 ", label: "DK8900 99876 77767 88788 " },
    { value: "DK8900 99876 77767 88788 ", label: "DK8900 99876 77767 88788 " },
    { value: "DK8900 99876 77767 88788 ", label: "DK8900 99876 77767 88788 " },
  ];
  const payreportDetails = [
    { value: "Every 3 months", label: "Every 3 months" },
    { value: "Every 1 months", label: "Every 1 months" },
    { value: "Every 2 months", label: "Every 2 months" },
    { value: "Every 3 months", label: "Every 3 months" },
    { value: "Every 4 months", label: "Every 4 months" },
    { value: "Every 3 months", label: "Every 3 months" },
  ];

  const handlepercentageChange = (event) => {
    setpercentage(event.target.value);
  };

  const handleaccountsChange = (event) => {
    setaccounts(event.target.value);
  };
  const handlepayChange = (event) => {
    setpayreport(event.target.value);
  };

  const handleClearpercentage = () => {
    setpercentage("");
  };
  const handleClearaccounts = () => {
    setaccounts("");
  };
  const handleClearpay = () => {
    setpayreport("");
  };

  return (
    <>
      <Box sx={{ py: "50px" }}>
        <Grid
          container
          columnSpacing={{ md: "52px", lg: "35px", xl: "56px" }}
          rowSpacing={{ xs: "46px", md: "0" }}
          columns={24}
        >
          <Grid item xs={24} md={12} lg={13} xl={14} xxxl={15} bs={16}>
            <Card
              sx={{
                backgroundColor: "#eef1f1",
                p: 0,
                justifyContent: "space-between",
              }}
            >
              <CardContent
                sx={{
                  p: {
                    xs: "36px 20px",
                    xl: "36px 64px",
                    xxxl: "36px 152px 64px 64px",
                  },
                  width: "100%",
                }}
              >
                <Typography variant="h2" component="h2" sx={{ mb: "15px" }}>
                  Tax pots
                </Typography>
                <Typography variant="subtitle2" component="p">
                  Save up your <strong>VAT</strong> to avoid unexpected{" "}
                  <strong>VAT payments</strong>
                </Typography>
                <Box sx={{ mt: "31px" }}>
                  <FormControl fullWidth sx={{ mb: "10px" }}>
                    <Stack direction={"row"} alignItems={"start"}>
                      <Typography
                        variant="subtitle1"
                        component="label"
                        sx={{ mb: "5px" }}
                      >
                        Choose what <strong>percentage</strong> of your{" "}
                        <strong>revenue</strong> you want us to save for you
                      </Typography>
                      <Tooltip
                        title="Choose what percentage of your revenue you want us to save for you"
                        arrow
                      >
                        <HelpOutlineRounded
                          sx={{ ml: "10px", fontSize: "16px" }}
                        />
                      </Tooltip>
                    </Stack>

                    <Select
                      labelId="percentage"
                      id="percentage"
                      value={percentage}
                      onChange={handlepercentageChange}
                      IconComponent={ExpandMoreRounded}
                      className="commonSelect"
                      endAdornment={
                        percentage && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClearpercentage}
                              sx={{ mr: 3 }}
                            >
                              <Clear sx={{ fontSize: "18px" }} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      {revenue.map((comp) => (
                        <MenuItem
                          key={comp.value}
                          value={comp.value}
                          sx={{
                            padding: "15px 16px",
                            fontSize: 14,
                            fontWeight: 400,
                            "&.MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "#eef1f1",
                              "&:hover": {
                                backgroundColor: "#eef1f1",
                              },
                            },
                          }}
                        >
                          {comp.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: "10px" }}>
                    <Stack direction={"row"} alignItems={"start"}>
                      <Typography
                        variant="subtitle1"
                        component="label"
                        sx={{ mb: "5px" }}
                      >
                        Choose which of your <strong>accounts</strong> to save
                        your <strong>VAT</strong> on
                      </Typography>
                      <Tooltip
                        title="Choose which of your accounts to save your VAT on"
                        arrow
                      >
                        <HelpOutlineRounded
                          sx={{ ml: "10px", fontSize: "16px" }}
                        />
                      </Tooltip>
                    </Stack>

                    <Select
                      labelId="accounts"
                      id="accounts"
                      value={accounts}
                      onChange={handleaccountsChange}
                      IconComponent={ExpandMoreRounded}
                      className="commonSelect"
                      endAdornment={
                        accounts && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClearaccounts}
                              sx={{ mr: 3 }}
                            >
                              <Clear sx={{ fontSize: "18px" }} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      {accountsDetails.map((comp) => (
                        <MenuItem
                          key={comp.value}
                          value={comp.value}
                          sx={{
                            padding: "15px 16px",
                            fontSize: 14,
                            fontWeight: 400,
                            "&.MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "#eef1f1",
                              "&:hover": {
                                backgroundColor: "#eef1f1",
                              },
                            },
                          }}
                        >
                          {comp.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <Stack direction={"row"} alignItems={"start"}>
                      <Typography
                        variant="subtitle1"
                        component="label"
                        sx={{ mb: "5px" }}
                      >
                        How often do you have to <strong>pay</strong> and{" "}
                        <strong>report</strong> VAT?
                      </Typography>
                      <Tooltip
                        title="How often do you have to pay and report VAT?"
                        arrow
                      >
                        <HelpOutlineRounded
                          sx={{ ml: "10px", fontSize: "16px" }}
                        />
                      </Tooltip>
                    </Stack>

                    <Select
                      labelId="payreport"
                      id="payreport"
                      value={payreport}
                      onChange={handlepayChange}
                      IconComponent={ExpandMoreRounded}
                      className="commonSelect"
                      endAdornment={
                        payreport && (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClearpay} sx={{ mr: 3 }}>
                              <Clear sx={{ fontSize: "18px" }} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      {payreportDetails.map((comp) => (
                        <MenuItem
                          key={comp.value}
                          value={comp.value}
                          sx={{
                            padding: "15px 16px",
                            fontSize: 14,
                            fontWeight: 400,
                            "&.MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "#eef1f1",
                              "&:hover": {
                                backgroundColor: "#eef1f1",
                              },
                            },
                          }}
                        >
                          {comp.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    p: "19px 14px",
                    mt: "13px",
                    backgroundColor: "primary.main",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      maxWidth: 300,
                      color: "#fff",
                      pl: "28px",
                      lineHeight: "23px",
                    }}
                    variant="subtitle1"
                  >
                    I <strong>accept</strong> that<strong> Pemmo</strong> may
                    automatically transfer on behalf of the{" "}
                    <strong>client</strong> the requested{" "}
                    <strong>percentage</strong> of revenue to a{" "}
                    <strong>VAT</strong> savings account chosen by the{" "}
                    <strong>client</strong>.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                    }
                    label="I accept the terms and conditions and would like continue."
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "10px",
                        lineHeight: "23px",
                        color: "#FFFFFF",
                      },
                    }}
                  />
                </Box>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "100%", height: 64, alignItems: "start" }}
                >
                  <Typography variant="body2">Activate</Typography>
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={24} md={12} lg={11} xl={10} xxxl={9} bs={8}>
            <Card
              sx={{
                backgroundColor: "#eef1f1",
                p: 0,
                justifyContent: "space-between",
              }}
            >
              <CardContent sx={{ p: 0, width: "100%" }}>
                <Box sx={{ p: { xs: "36px 20px 20px", md: "35px 27px 20px" } }}>
                  <Typography variant="h2" component="h2" sx={{ mb: "16px" }}>
                    Active Tax pots
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    sx={{ maxWidth: 207 }}
                  >
                    Select a vendor you want to transfer money to or pay a bill
                    to
                  </Typography>
                </Box>
                <Box sx={{ mb: "51px" }}>
                  <Box
                    sx={{
                      backgroundColor: "#0a38f0",
                      color: "#ffffff",
                      textAlign: "center",
                      py: "2px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{ color: "#fff" }}
                    >
                      17%
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{ color: "#fff" }}
                    >
                      DK8900 99876 77767 88788
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        width: "65%",
                        height: 24,
                        justifyContent: "center",
                        boxShadow: "none",
                        fontSize: 10,
                        fontWeight: 700,
                        borderRadius: 0,
                      }}
                    >
                      Active
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setChangePerModel(true)}
                      sx={{
                        width: "35%",
                        height: 24,
                        justifyContent: "center",
                        boxShadow: "none",
                        fontSize: 10,
                        backgroundColor: "#fff",
                        color: "#000",
                        borderRadius: 0,
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{
                      backgroundColor: "#0a38f0",
                      color: "#ffffff",
                      textAlign: "center",
                      py: "2px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{ color: "#fff" }}
                    >
                      20%
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{ color: "#fff" }}
                    >
                      UK8739 99876 77767 88788
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        width: "65%",
                        height: 24,
                        justifyContent: "center",
                        boxShadow: "none",
                        fontSize: 10,
                        fontWeight: 700,
                        borderRadius: 0,
                      }}
                    >
                      Active
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setChangePerModel(true)}
                      sx={{
                        width: "35%",
                        height: 24,
                        justifyContent: "center",
                        boxShadow: "none",
                        fontSize: 10,
                        backgroundColor: "#fff",
                        color: "#000",
                        borderRadius: 0,
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ChangePerModel open={changePermodel} setOpen={setChangePerModel} />
    </>
  );
};

export default Textport;
