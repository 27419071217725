import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ImportExportRoundedIcon from "@mui/icons-material/ImportExportRounded";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import UsdToEurChart from "./usd-to-eur-chart";

const AccountSelect = ({ label, flag, accountName, amount, currency }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      mb: 2,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="subtitle2"
        sx={{ width: { xs: "50px", md: "60px" } }}
      >
        {label}
      </Typography>
      <Avatar sx={{ width: 15, height: 15, mr: "10px" }}>
        <span
          className={`fi fi-${flag}`}
          style={{ transform: "scale(1.5)" }}
        ></span>
      </Avatar>
      <Typography
        variant="h6"
        sx={{
          fontSize: {
            "@media (max-width:576px)": {
              fontSize: 12,
            },
            "@media (max-width:400px)": {
              fontSize: 10,
            },
          },
        }}
      >
        {accountName}
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="h6"
        sx={{
          fontSize: {
            "@media (max-width:576px)": {
              fontSize: 12,
            },
            "@media (max-width:400px)": {
              fontSize: 10,
            },
          },
        }}
      >
        {currency}
        {amount}
      </Typography>
      <IconButton>
        <ExpandMoreRoundedIcon />
      </IconButton>
    </Box>
  </Box>
);

const FxConverter = () => {
  const accounts = [
    {
      label: "From",
      flag: "us",
      accountName: "USD Account",
      amount: "114,158.63",
      currency: "$",
    },
    {
      label: "Send to",
      flag: "eu",
      accountName: "EUR Account",
      amount: "74,250.30",
      currency: "€",
    },
  ];
  return (
    <>
      <Box sx={{ paddingTop: "42px" }}>
        <Grid
          container
          columnSpacing={{ lg: "35px", xl: "56px" }}
          rowSpacing={{ xs: "46px", lg: "0" }}
          columns={24}
        >
          <Grid item xs={24} lg={12}>
            <Card
              sx={{
                backgroundColor: "#eef1f1",
                justifyContent: "space-between",
                p: 0,
              }}
            >
              <CardContent
                sx={{
                  p: {
                    xs: "66px 22px 0",
                    md: "126px 28px 90px",
                    xl: "126px 28px 90px",
                  },
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    mb: "70px",
                  }}
                >
                  <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                      fontSize: { xs: 40, sm: 60, md: 80, lg: 42 },
                      mb: "10px",
                    }}
                  >
                    $148.50
                  </Typography>
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                      mb: "10px",
                      fontSize: { xs: 30, sm: 35, md: 40, lg: 24 },
                    }}
                  >
                    €140,30
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ mb: "40px" }}>
                    0.95635624
                    <IconButton sx={{ ml: "4px" }}>
                      <ArrowDropUpIcon sx={{ color: "#23E33E" }} />
                    </IconButton>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      minWidth: 52,
                      width: 52,
                      height: 52,
                      p: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ImportExportRoundedIcon sx={{ fontSize: "25px" }} />
                  </Button>
                </Box>
                <Box sx={{ width: "100%" }}>
                  {accounts.map((account, index) => (
                    <Fragment key={index}>
                      <AccountSelect {...account} />
                      {index < accounts.length - 1 && (
                        <Box sx={{ my: "16px" }}>
                          <Divider sx={{ borderColor: "#000000" }} />
                        </Box>
                      )}
                    </Fragment> 
                  ))}
                </Box>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    width: "50%",
                    height: 64,
                    alignItems: "start",
                    borderRadius: 0,
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: "50%",
                    height: 64,
                    alignItems: "start",
                    borderRadius: 0,
                  }}
                >
                  Continue exchange
                  <EastRoundedIcon sx={{ fontSize: "20px" }} />
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item lg={12} sx={{ display: { xs: "none", lg: "flex" } }}>
            <Card
              sx={{
                backgroundColor: "#eef1f1",
                p: 0,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  padding: "20px 24px",
                  borderBottom: "1px solid #CFDBD5",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                  USD to EUR Chart
                </Typography>
              </Box>
              <CardContent
                sx={{
                  p: "31px 17px 8px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <UsdToEurChart />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FxConverter;
