import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
export default function ChangePerModel({ open, setOpen }) {
  const [value, setValue] = useState(17);

  const handleIncrement = () => {
    setValue((prevValue) => Math.min(prevValue + 1, 100));
  };

  const handleDecrement = () => {
    setValue((prevValue) => Math.max(prevValue - 1, 0));
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent sx={{ padding: 0, maxWidth: 304 }}>
        <Box
          sx={{
            overflowX: "hidden",
            overflowY: "auto",
            pt: "49px",
            pb: "71px",
            px: "36px",
            backgroundColor: "#eef1f1",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#161616",
              textAlign: "center",
            }}
          >
            Change the percentage
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "3px solid #0f62fe",
              height: 48,
              mt: "49px",
              overflow: "hidden",
            }}
          >
            <TextField
              className="incrementInput"
              value={`${value}%`}
              InputProps={{
                readOnly: true,
              }}
              sx={{ border: "none", outline: "none", background: "#eef1f1" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleDecrement} sx={{ px: "15px" }}>
                <RemoveIcon sx={{ fontSize: "15px" }} />
              </IconButton>
              <IconButton
                onClick={handleIncrement}
                sx={{ px: "15px", borderLeft: "1px solid gray" }}
              >
                <AddIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button
          variant="contained"
          sx={{
            width: 152,
            height: 48,
            backgroundColor: "#FFFFFF",
            color: "#000000",
            borderRadius: 0,
            "&:hover": {
              backgroundColor: "#FFFFFF",
              color: "#000000",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            width: 152,
            height: 48,
            borderRadius: 0,
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
