import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import * as Yup from "yup";

// Validation schema
const validations = Yup.object({
  registeredCountry: Yup.string().required("Country is required"),
  operationalCountry: Yup.string(),
});

const StepTwo = () => {
  const options = useMemo(() => countryList().getData(), []);

  const formikConfig = {
    initialValues: {
      registeredCountry: "",
      operationalCountry: "",
    },
    validationSchema: validations,
    onSubmit: (values, { resetForm }) => {
      console.log("step 2 data:", values);
      resetForm();
    },
  };

  const renderSelect = (id, name, value, handleChange, handleBlur, touched, errors) => (
    <FormControl
      fullWidth
      sx={{
        backgroundColor: "#fff",
        pt: "13px",
        borderBottom: "3px solid #000",
      }}
    >
      <Typography id={id} variant="subtitle2" component="label" sx={{ px: "15px" }}>
        {name === "registeredCountry"
          ? "Where is your company registered?"
          : "Country of operations (Optional)"}
      </Typography>
      <Select
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className="noBorderSelect"
        IconComponent={ExpandMoreRounded}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em>Country</em>;
          }
          const selectedCountry = options.find((option) => option.value === selected);
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span
                className={`fi fi-${selectedCountry.value.toLowerCase()}`}
                style={{ marginRight: 10 }}
              />
              {selectedCountry.label}
            </Box>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {options.map((country) => (
          <MenuItem
            key={country.value}
            value={country.value}
            sx={{
              padding: "15px 16px",
              fontSize: 14,
              fontWeight: 400,
              "&.MuiMenuItem-root.Mui-selected": {
                backgroundColor: "#eef1f1",
                "&:hover": {
                  backgroundColor: "#eef1f1",
                },
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span
                className={`fi fi-${country.value.toLowerCase()}`}
                style={{ marginRight: 10 }}
              />
              {country.label}
            </Box>
          </MenuItem>
        ))}
      </Select>
      {touched[name] && errors[name] && (
        <Typography variant="subtitle2" sx={{ color: "red", mt: "4px" }}>
          {errors[name]}
        </Typography>
      )}
    </FormControl>
  );

  return (
    <>
      <Box sx={{ width: "100%", maxWidth: "520px", margin: "30px auto" }}>
        <Box>
          <Typography
            variant="h5"
            sx={{ fontWeight: 400, textAlign: "center" }}
          >
            Already have an account?{" "}
            <Link
              to="#"
              underline="always"
              sx={{ fontWeight: 700 }}
              color="inherit"
            >
              Sign in
            </Link>
          </Typography>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontWeight: 600,
              fontSize: 32,
              lineHeight: "normal",
              my: "50px",
            }}
          >
            Open your international business account with Pemmo.Money
          </Typography>
        </Box>
        <Box>
          <Formik {...formikConfig}>
            {({ values, handleChange, handleBlur, touched, errors }) => (
              <Form>
                {renderSelect(
                  "registered-country-select",
                  "registeredCountry",
                  values.registeredCountry,
                  handleChange,
                  handleBlur,
                  touched,
                  errors
                )}
                
                <Accordion className="authAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ margin: 0 }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        component="label"
                        sx={{ mb: "10px" }}
                      >
                        Country of operations (Optional)
                      </Typography>
                      <Typography
                        variant="body2"
                        component="h6"
                        sx={{ mb: "4px", fontWeight: 500 }}
                      >
                        If the business operates from a different country than
                        country of registration,{" "}
                        <Link to={"#"} sx={{ fontWeight: 700 }}>
                          please select it here
                        </Link>
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {renderSelect(
                      "operational-country-select",
                      "operationalCountry",
                      values.operationalCountry,
                      handleChange,
                      handleBlur,
                      touched,
                      errors
                    )}
                  </AccordionDetails>
                </Accordion>

                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{
                    height: 64,
                    alignItems: "start",
                    borderRadius: 0,
                  }}
                >
                  Next
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default StepTwo;
