import React from "react";

import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Box, Button } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import PemmoImgOne from "../../assets/images/pemmo-card1.png";
import PemmoImgTwo from "../../assets/images/pemmo-card2.png";
import PemmoImgThree from "../../assets/images/pemmo-card3.png";
import PemmoImgFour from "../../assets/images/pemmo-card4.png";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Allcards = () => {
  const cardsData = [
    {
      imgSrc: PemmoImgOne,
      alt: "Card",
      buttonText: "See card details",
    },
    {
      imgSrc: PemmoImgTwo,
      alt: "Card",
      buttonText: "See card details",
    },
    {
      imgSrc: PemmoImgFour,
      alt: "Card",
      buttonText: "See card details",
    },
    {
      imgSrc: PemmoImgThree,
      alt: "Card",
      buttonText: "See card details",
    },
  ];

  return (
    <Box
      sx={{
        mt: "132px",
      }}
    >
      <Box
        sx={{
          maxWidth: "900px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            500: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 55,
            },
          }}
          modules={[Navigation, Pagination]}
          className="mySwiper"
          style={{ width: "100%" }}
        >
          {cardsData.map((card, index) => (
            <SwiperSlide key={index}>
              <Box>
                <Box
                  sx={{
                    maxWidth: 203,
                    height: 320,
                    width: "100%",
                    boxShadow: "6px 6px 6px 0px #00000040",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={card.imgSrc}
                    alt={card.alt}
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: 183, height: 40, mt: "27px", mx: "10px" }}
                >
                  {card.buttonText}{" "}
                  <EastRoundedIcon sx={{ fontSize: "20px" }} />
                </Button>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            justifyContent: "center",
            alignItems: "center",
            ml: "40px",
          }}
        >
          <Box
            className="swiper-button-prev"
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "#0012F0",
              p: "5px",
              borderRadius: "5px",
            }}
          >
            <ArrowBackIcon
              className="text-pemmo-success"
              sx={{ color: "#ffffff" }}
            />
          </Box>
          <Box
            className="swiper-button-next"
            sx={{
              width: 30,
              height: 30,
              backgroundColor: "#0012F0",
              p: "5px",
              borderRadius: "5px",
            }}
          >
            <ArrowForwardIcon
              className="text-pemmo-success"
              sx={{ color: "#ffffff" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Allcards;
