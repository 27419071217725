import React, { useState } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PemmoCardImg1 from "../../assets/images/pemmo-card1.png";
import PemmoCardImg3 from "../../assets/images/pemmo-card3.png";
import TransactionsMenu from "./transactions-menu";
import TransactionsTable from "../../common/transactions";

const transactionsData = [
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
];

const CardWithMenu = ({ image }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <Card sx={{ width: { xs: 100, md: 132 }, p: 0, borderRadius: "6px" }}>
      <img src={image} alt="" width={"100%"} height={"100%"} />
    </Card>
    <TransactionsMenu>
      <MoreHorizRoundedIcon
        sx={{ fontSize: "25px", mt: 0.5, cursor: "pointer", color: "#000000" }}
      />
    </TransactionsMenu>
  </Box>
);

const AccountCard = ({ flagClass, accountNumber, balance }) => (
  <Card
    sx={{
      flexDirection: "row",
      height: 80,
      alignItems: "center",
      padding: { xs: "16px 20px", lg: "16px 24px" },
      mb: "14px",
      position: "relative",
    }}
  >
    <Avatar
      sx={{
        width: 24,
        height: 24,
        mr: {
          xs: "12px",
          xl: "32px",
        },
      }}
    >
      <span className={flagClass} style={{ transform: "scale(1.5)" }}></span>
    </Avatar>
    <Stack direction="row" spacing={"20px"}>
      <Box>
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontSize: { xs: 12, xl: 14 } }}
        >
          {accountNumber}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={{ fontSize: { xs: 10, sm: 12, xl: 14 } }}
        >
          Account number
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontSize: { xs: 12, xl: 14 } }}
        >
          {balance}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={{ fontSize: { xs: 10, sm: 12, xl: 14 } }}
        >
          Account balance
        </Typography>
      </Box>
    </Stack>
    <Box
      sx={{
        width: { xs: 62, lg: 72 },
        backgroundColor: "primary.main",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0,
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "primary.dark",
        },
      }}
    >
      <EastRoundedIcon sx={{ color: "#fff", fontSize: "35px" }} />
    </Box>
  </Card>
);

const Dashboard = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [copyStatus, setCopyStatus] = useState({
    iban: false,
    bic: false,
  });

  const handleVisibilityToggle = () => {
    setIsVisible((prev) => !prev);
  };

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopyStatus((prev) => ({ ...prev, [field]: true }));
    setTimeout(() => {
      setCopyStatus((prev) => ({ ...prev, [field]: false }));
    }, 2000);
  };
  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  const value = "DKK 0.00";
  const hiddenValue = value.replace(/./g, "*");

  return (
    <Box>
      <Grid container rowSpacing={"14px"} columnSpacing={"14px"} columns={24}>
        <Grid item xs={24} md={12}>
          <Card
            sx={{
              padding: {
                xs: "20px",
                md: "20px 28px",
                xxl: "40px 24px 24px 40px",
              },
              flexDirection: "row",
              alignItems: "center",
              height: 174,
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 1, mr: { xs: "20px", lg: "10px" } }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                sx={{ width: "100%", maxWidth: "250px" }}
              >
                <Typography variant="h1" component="h1">
                  {isVisible ? value : hiddenValue}
                </Typography>
                <IconButton
                  onClick={handleVisibilityToggle}
                  sx={{
                    ml: "15px",
                    p: 0,
                  }}
                >
                  {isVisible ? (
                    <VisibilityOffOutlinedIcon
                      sx={{
                        color: "#000",
                        fontSize: {
                          xs: "25px",
                          sm: "35px",
                          xl: "42px",
                        },
                      }}
                    />
                  ) : (
                    <VisibilityOutlinedIcon
                      sx={{
                        color: "#000",
                        fontSize: {
                          xs: "25px",
                          sm: "35px",
                          xl: "42px",
                        },
                      }}
                    />
                  )}
                </IconButton>
              </Box>
              <Box display="flex" alignItems="center" mb={"10px"}>
                <Typography variant="body1" component="p">
                  IBAN DK89009877635526
                </Typography>
                <IconButton
                  onClick={() => handleCopy("IBAN DK89009877635526", "iban")}
                  sx={{
                    ml: "8px",
                    p: 0,
                  }}
                >
                  {copyStatus.iban ? (
                    <CheckRoundedIcon
                      sx={{
                        color: "#000",
                        fontSize: {
                          xs: "16px",
                          sm: "20px",
                          xl: "26px",
                        },
                      }}
                    />
                  ) : (
                    <ContentCopyRoundedIcon
                      sx={{
                        color: "#000",
                        fontSize: {
                          xs: "16px",
                          sm: "20px",
                          xl: "26px",
                        },
                      }}
                    />
                  )}
                </IconButton>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" component="p">
                  BICDK900
                </Typography>
                <IconButton
                  onClick={() => handleCopy("BICDK900", "bic")}
                  sx={{
                    ml: "8px",
                    p: 0,
                  }}
                >
                  {copyStatus.bic ? (
                    <CheckRoundedIcon
                      sx={{
                        color: "#000",
                        fontSize: {
                          xs: "16px",
                          sm: "20px",
                          xl: "26px",
                        },
                      }}
                    />
                  ) : (
                    <ContentCopyRoundedIcon
                      sx={{
                        color: "#000",
                        fontSize: {
                          xs: "16px",
                          sm: "20px",
                          xl: "26px",
                        },
                      }}
                    />
                  )}
                </IconButton>
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                minWidth: 40,
                width: 40,
                height: 40,
                p: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <NavigateNextRoundedIcon sx={{ fontSize: "30px" }} />
            </Button>
          </Card>
        </Grid>
        <Grid item xs={24} md={12}>
          <AccountCard
            flagClass="fi fi-dk"
            accountNumber="8900 12**"
            balance="DKK 0.00"
          />
          <AccountCard
            flagClass="fi fi-gb"
            accountNumber="7890 45**"
            balance="GBP 0.00"
          />
        </Grid>
        <Grid item xs={24}>
          <Box
            sx={{
              backgroundColor: "secondary.main",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Typography variant="h3" component="h3">
                Latest transactions
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={{ color: "#525252" }}
              >
                All accounts combined
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#eaeaea",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  flexGrow: 1,
                }}
              >
                {isSearchOpen ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search your transactions"
                    fullWidth
                    className="tableSearchInput"
                    sx={{ height: 32 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={toggleSearch}
                          sx={{ cursor: "pointer" }}
                        >
                          <SearchRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <IconButton onClick={toggleSearch} sx={{ px: 1 }}>
                    <SearchRoundedIcon />
                  </IconButton>
                )}
              </Box>
              <Button variant="contained" sx={{ width: 198, height: 32 }}>
                Go to transactions <EastRoundedIcon sx={{ fontSize: "20px" }} />
              </Button>
            </Box>
            <TransactionsTable data={transactionsData} />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: "34px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <Card
          sx={{
            mr: { md: "25px", lg: "38px" },
            mb: { xs: "25px", md: "0" },
            flexGrow: 1,
            padding: {
              xs: "20px",
              md: "20px 28px",
              xxl: "30px 20px 20px 30px",
            },
            height: { xs: "unset", md: 210 },
            justifyContent: "center",
            width: "100%",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontWeight: 300,
              fontSize: {
                xs: 35,
                md: 22,
                xl: 30,
                xxl: 40,
              },
              fontStyle: "italic",
            }}
          >
            Invite a Business Friend and
          </Typography>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: {
                xs: 35,
                md: 22,
                xl: 30,
                xxl: 40,
              },
            }}
          >
            Earn €80
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "start", lg: "end" },
              width: "100%",
            }}
          >
            <Button variant="contained" sx={{ width: 148 }}>
              Invite now <EastRoundedIcon sx={{ fontSize: "20px" }} />
            </Button>
          </Box>
          <IconButton
            sx={{ position: "absolute", right: "10px", top: "10px", p: 0.5 }}
          >
            <CloseRoundedIcon sx={{ color: "#000" }} />
          </IconButton>
        </Card>
        <Stack direction="row" spacing={{ xs: "14px", lg: "29px" }}>
          <CardWithMenu image={PemmoCardImg1} />
          <CardWithMenu image={PemmoCardImg3} />
        </Stack>
      </Box>
    </Box>
  );
};

export default Dashboard;
