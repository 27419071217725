import React from "react";
import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import PropTypes from "prop-types";

const CustomPagination = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  dataLength,
}) => {
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleRowsPerPageChange = (event) => {
    onRowsPerPageChange(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#f4f4f4",
      }}
    >
      {/* Rows per page selector */}
      <Select
        value={rowsPerPage}
        className="paginationSelect"
        onChange={handleRowsPerPageChange}
        sx={{ height: 32 }}
      >
        {[5, 10, 25, 100].map((rows) => (
          <MenuItem
            key={rows}
            value={rows}
            sx={{
              padding: "2px 4px",
              fontSize: 12,
              fontWeight: 400,
              "&.MuiMenuItem-root.Mui-selected": {
                backgroundColor: "#eef1f1",
                "&:hover": {
                  backgroundColor: "#eef1f1",
                },
              },
            }}
          >
            {rows}
          </MenuItem>
        ))}
      </Select>

      {/* Showing range */}
      <Box
        flexGrow={1}
        sx={{
          border: "1px solid transparent",
          borderRightColor: "#c6c6c6",
          borderLeftColor: "#c6c6c6",
        }}
      >
        <Typography variant="body2" sx={{ color: "#525252", px: 2 }}>
          {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, count)}{" "}
          of {count} transactions
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Page selector */}
        <Select
          value={page + 1}
          className="paginationSelect"
          onChange={(event) => onPageChange(event, event.target.value - 1)}
          sx={{ height: 32, display: { xs: "none", md: "flex" } }}
        >
          {[...Array(Math.ceil(count / rowsPerPage)).keys()].map((pageNum) => (
            <MenuItem
              key={pageNum + 1}
              value={pageNum + 1}
              sx={{
                padding: "2px 4px",
                fontSize: 14,
                fontWeight: 400,
                "&.MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "#eef1f1",
                  "&:hover": {
                    backgroundColor: "#eef1f1",
                  },
                },
              }}
            >
              {pageNum + 1}
            </MenuItem>
          ))}
        </Select>

        {/* Total count */}
        <Typography
          variant="body2"
          sx={{ pr: 2, display: { xs: "none", md: "block" } }}
        >
          of {count} transactions
        </Typography>

        {/* Navigation buttons */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
            sx={{
              width: 32,
              height: 32,
              border: "1px solid transparent",
              borderLeftColor: "#c6c6c6",
              borderRadius: 0,
            }}
          >
            <ArrowLeftRoundedIcon sx={{ color: "#000", fontSize: "28px" }} />
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
            sx={{
              width: 32,
              height: 32,
              border: "1px solid transparent",
              borderRightColor: "#c6c6c6",
              borderLeftColor: "#c6c6c6",
              borderRadius: 0,
            }}
          >
            <ArrowRightRoundedIcon sx={{ color: "#000", fontSize: "28px" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

CustomPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  dataLength: PropTypes.number.isRequired,
};

export default CustomPagination;
