import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

// Validation schema using Yup
const validations = Yup.object({
  registration_number: Yup.string().required(
    "Business registration number is required"
  ),
});

const StepThree = () => {
  const options = [
    { value: "acme_as", label: "Acme A/S" },
    { value: "acme_aps", label: "Acme ApS" },
    { value: "company_is", label: "Company I/S" },
    { value: "lars_larsen", label: "Lars Larsen" },
    { value: "firma_as", label: "Firma A/S" },
    { value: "firma_aps", label: "Firma ApS" },
  ];

  const formikConfig = {
    initialValues: {
      registration_number: "",
    },
    validationSchema: validations,
    onSubmit: (values, { resetForm }) => {
      console.log("Step 3 data:", values);
      resetForm();
    },
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "520px", margin: "30px auto" }}>
      <Box sx={{ mb: "50px" }}>
        <Typography variant="h5" sx={{ fontWeight: 400, textAlign: "center" }}>
          Already have an account?{" "}
          <Link
            to="#"
            underline="always"
            sx={{ fontWeight: 700 }}
            color="inherit"
          >
            Sign in
          </Link>
        </Typography>
      </Box>
      <Box>
        <Formik {...formikConfig}>
          {({ values, handleChange, handleBlur, touched, errors }) => (
            <Form>
              <Typography
                id="registration-number-select"
                variant="subtitle2"
                component="label"
                sx={{ fontWeight: 700, mb: "4px" }}
              >
                Business registration number
              </Typography>
              <FormControl
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderBottom: "3px solid #000",
                }}
              >
                <Select
                  id="registration_number"
                  name="registration_number"
                  value={values.registration_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="noBorderSelect"
                  IconComponent={ExpandMoreRounded}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 260,
                      },
                    },
                  }}
                >
                  {options.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      sx={{
                        padding: "15px 16px",
                        fontSize: 14,
                        fontWeight: 400,
                        "&.MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#eef1f1",
                          "&:hover": {
                            backgroundColor: "#eef1f1",
                          },
                        },
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.registration_number && errors.registration_number && (
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "red", mt: "4px" }}
                  >
                    {errors.registration_number}
                  </Typography>
                )}
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                color="success"
                fullWidth
                sx={{
                  height: 64,
                  alignItems: "start",
                  borderRadius: 0,
                }}
              >
                Next
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default StepThree;
