import { KeyboardBackspaceRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoginPortal from "../../../assets/images/login-portal.png";
import Logo from "../../../assets/images/pemmo-logo.svg";
import AppleIcon from "../../../assets/icons/Apple.svg";
import GoogleIcon from "../../../assets/icons/Google.svg";
import AppleStore from "../../../assets/icons/apple-store.svg";
import GoogleStore from "../../../assets/icons/google-store.svg";
import RatingImg from "../../../assets/images/rating.svg";
import StarsImg from "../../../assets/images/stars.svg";

const Login = () => {
  const [isOtpStage, setIsOtpStage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const handleNext = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsOtpStage(true);
    }, 2000);
  };

  const handleBack = () => {
    setIsOtpStage(false);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100%" }}>
      <Box
        sx={{
          width: {
            xs: "100%",
            md: 450,
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "45px",
        }}
      >
        <Box sx={{mb: "50px"}}>
          <Link to="#" underline="none">
            <img src={Logo} alt="logo" width={200} height={50} />
          </Link>
        </Box>

        <Box>
          {!isOtpStage ? (
            <>
              <Typography variant="h3" sx={{ mb: "20px" }}>
                Login
              </Typography>
              <Box sx={{ mb: "25px" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                  Your phone number
                </Typography>
                <PhoneInput
                  country={"us"}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  inputStyle={{
                    width: "100%",
                    height: 30,
                    border: "2px solid transparent",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "0",
                    paddingLeft: 55,
                  }}
                  buttonStyle={{
                    height: 30,
                    border: "2px solid transparent",
                    borderRightColor: "#000",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "2px",
                    width: 50,
                    top: "5px",
                    padding: "0px",
                  }}
                  containerStyle={{
                    padding: "5px",
                    border: "2px solid #000",
                    backgroundColor: "#f5f5f5",
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="success"
                sx={{
                  height: 30,
                  width: 100,
                  p: "6px 10px",
                  borderRadius: 0,
                }}
                onClick={handleNext}
                disabled={loading}
              >
                Next
                {loading ? (
                  <CircularProgress size={16} sx={{ color: "#000" }} />
                ) : null}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={handleBack}
                variant="contained"
                color="success"
                sx={{
                  height: 30,
                  width: 100,
                  p: "6px 10px",
                  borderRadius: 0,
                  mb: "40px",
                }}
              >
                <KeyboardBackspaceRounded size={16} />
                Back
              </Button>
              <Typography variant="h3">Enter your 4 digits passcode</Typography>
              <Typography variant="body2">
                This is the secret code you chose when you created your account.
                <strong>This code is not sent to you by text.</strong>
              </Typography>
              <Box my={"30px"}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: "64px",
                    height: "64px",
                    fontSize: "30px",
                    borderRadius: "0",
                    border: "3px solid black",
                    fontWeight: 700,
                  }}
                  containerStyle={{
                    justifyContent: "space-between",
                  }}
                  shouldAutoFocus
                />
              </Box>
              <Button
                variant="contained"
                color="success"
                sx={{
                  height: 30,
                  width: 100,
                  p: "6px 10px",
                  borderRadius: 0,
                  mt: 2,
                }}
              >
                Next
              </Button>
            </>
          )}
          <Box mt={"30px"} mb={"70px"}>
            <Typography variant="h5" sx={{ fontWeight: 400 }}>
              No Pemmo account yet? <br />
              <Link
                to="#"
                underline="always"
                sx={{ fontWeight: 700 }}
                color="inherit"
              >
                Open an account
              </Link>
            </Typography>
          </Box>
          {!isOtpStage && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "17px" }}>
              <Button
                variant="outlined"
                className="blackBtn"
                sx={{
                  height: 40,
                  borderRadius: 0,
                }}
              >
                <Box mr={"8px"}>
                  <img src={AppleIcon} alt="apple" />
                </Box>
                Login via Apple
              </Button>
              <Button
                variant="outlined"
                className="blackBtn"
                sx={{
                  height: 40,
                  borderRadius: 0,
                }}
              >
                <Box mr={"8px"}>
                  <img src={GoogleIcon} alt="apple" />
                </Box>
                Login via Google
              </Button>
            </Box>
          )}
        </Box>
        <List sx={{ display: "flex", p: 0, mt: "50px" }} className="footerList">
          <ListItem sx={{ width: "auto", p: 0, mr: 1 }}>
            <ListItemText>
              <Link to="#" underline="none">
                Privacy Policy
              </Link>
            </ListItemText>
          </ListItem>

          <ListItem sx={{ width: "auto", p: 0, mx: 1 }}>
            <ListItemText>
              <Link to="#" underline="none">
                Terms & Conditions
              </Link>
            </ListItemText>
          </ListItem>

          <ListItem sx={{ width: "auto", p: 0, mx: 1 }}>
            <ListItemText>
              <Link to="#" underline="none">
                Help Center
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          background: "#f8f8f8",
          width: 0,
          p: "45px",
          display: {
            xs: "none",
            md: "flex",
          },
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h1"
            component="h1"
            sx={{ textAlign: "center", fontSize: "50px" }}
          >
            Your business account made easy
          </Typography>
          <Box sx={{ width: "92%", margin: "0 auto" }}>
            <img src={LoginPortal} alt="cards..." width={"100%"} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              lg: "space-between",
            },
            alignItems: "end",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: {
                xs: "100%",
                lg: "52%",
              },
              maxWidth: "52%",
              order: {
                xs: "2",
                lg: "1",
              },
              mt: {
                xs: "20px",
                lg: "0",
              },
            }}
          >
            <img src={RatingImg} alt="rating img" width={"49%"} />
            <img src={StarsImg} alt="Stars img" width={"49%"} />
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                lg: "unset",
              },
              order: {
                xs: "1",
                lg: "2",
              },
            }}
          >
            <Typography
              variant="body2"
              component="p"
              sx={{ textAlign: "center", mb: "20px" }}
            >
              Now available on
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <Link
                to="#"
                underline="none"
                sx={{
                  maxWidth: {
                    xs: "120px",
                    lg: "100px",
                    xl: "120px",
                  },
                }}
              >
                <img
                  src={AppleStore}
                  alt="apple store"
                  width={"100%"}
                  height={"110%"}
                />
              </Link>
              <Link
                to="#"
                underline="none"
                sx={{
                  maxWidth: {
                    xs: "120px",
                    lg: "100px",
                    xl: "120px",
                  },
                }}
              >
                <img
                  src={GoogleStore}
                  alt="google store"
                  width={"100%"}
                  height={"110%"}
                />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
