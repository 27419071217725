import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ImportExportRoundedIcon from "@mui/icons-material/ImportExportRounded";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import Logo from "../../assets/images/symbol.svg";
const drawerWidth = 240;

export default function Navbar({ handleDrawerToggle }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { lg: `${drawerWidth}px` },
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={handleDrawerToggle}
          sx={{
            display: {
              lg: "none",
            },
            border: "unset",
            borderRadius: 0,
            "&:focus": {
              backgroundColor: "unset",
              boxShadow: "unset",
            },
            "&:active": {
              backgroundColor: "unset",
              boxShadow: "unset",
            },
          }}
        >
          <MenuIcon sx={{ color: "#000" }} />
        </Button>
        <Box
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            backgroundColor: "secondary.main",
            borderRadius: "0 0 5px 5px",
          }}
        >
          <List sx={{ display: "flex" }} className="navbarList">
            {[
              "Add money",
              "Send",
              "Receive",
              "FX Converter",
              "Supportcenter",
            ].map((item, index) => (
              <ListItem key={item} disablePadding>
                <ListItemButton
                  sx={{
                    textAlign: "center",
                    color: "#000",
                    whiteSpace: "nowrap",
                  }}
                >
                  <ListItemText primary={item} />
                  <ArrowRightRoundedIcon />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0 0 5px 5px" }}
            endIcon={<ImportExportRoundedIcon sx={{ width: "22px" }} />}
          >
            Switch to PemmoBooks™
          </Button>
        </Box>
        <Box
          sx={{
            display: {
              xs: "flex",
              lg: "none",
            },
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#000000", display: "flex", alignItems: "center" }}
          >
            All your financials
            <Typography
              variant="h6"
              sx={{
                color: "primary.main",
                fontWeight: 300,
                ml: 0.5,
              }}
            >
              one place
            </Typography>
          </Typography>
          <Box>
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: 25,
                height: 25,
                marginRight: 12,
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
