import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Logo from "../../assets/images/symbol.svg";

export default function AllRecepientsModel({ open, setOpen, title }) {
  const [selectedoffers, setSelectedoffers] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectRecipient = (name) => {
    setSelectedoffers((prev) => {
      if (prev.includes(name)) {
        return prev.filter((recipient) => recipient !== name);
      } else {
        return [...prev, name];
      }
    });
  };

  const offers = [
    { name: "Telia", logo: Logo },
    { name: "Apple store", logo: Logo },
    { name: "Office rent", logo: Logo },
    { name: "Google ads", logo: Logo },
    { name: "Spotify", logo: Logo },
    { name: "Salary", logo: Logo },
    { name: "test 1", logo: Logo },
    { name: "test 2", logo: Logo },
    { name: "test 3", logo: Logo },
    { name: "test 4", logo: Logo },
    { name: "test 5", logo: Logo },
    { name: "test 6", logo: Logo },
  ];

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent sx={{ padding: 0, maxWidth: 304 }}>
        <Box
          sx={{
            overflowX: "hidden",
            overflowY: "auto",
            p: { xs: "30px 10px", md: "30px" },
            maxHeight: "60vh",
            backgroundColor: "#eef1f1",
          }}
        >
          <Grid container columns={24} spacing={{ xs: "10px", md: "20px" }}>
            {offers.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt="img not found"
                    src={item.logo}
                    sx={{
                      width: 36,
                      height: 36,
                      boxShadow: "6px 6px 6px 0px #00000040",
                    }}
                  />
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ mt: "10px", mb: "5px" }}
                  >
                    {item.name}
                  </Typography>
                  <Button
                    variant={"contained"}
                    color={
                      selectedoffers.includes(item.name) ? "success" : "primary"
                    }
                    onClick={() => handleSelectRecipient(item.name)}
                    sx={{
                      px: "8px",
                      py: "4px",
                      fontSize: "10px",
                      justifyContent: "center",
                      boxShadow: "none",
                    }}
                  >
                    {selectedoffers?.includes(item.name)
                      ? "Selected"
                      : "Select"}
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ border: "1px solid #e8e8e8", width: "100%" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder={`Search ${title}`}
            fullWidth
            className="tableSearchInput"
            sx={{ height: 30, width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            padding: "15px 12px",
            backgroundColor: "#eef1f1",
            "&:hover": {
              backgroundColor: "#eef1f1",
            },
          }}
        >
          <Typography variant="body2">
            Create a <strong>{title}</strong>
          </Typography>
        </Button>
        <Button variant="contained" sx={{ width: "50%", padding: "15px 12px" }}>
          <Typography variant="body2" sx={{ color: "white" }}>
            See all <strong>{title}</strong>
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
