import React, { useState } from "react";
import {
  AddRounded,
  CheckRounded,
  Clear,
  ExpandMoreRounded,
  SearchRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import TransactionsTable from "../../common/transactions";
import DownloadTransactionsModel from "../../common/transactions/download-transactions";

import cardImgEUR from "../../assets/icons/card-eur.svg";
import cardImgUSD from "../../assets/icons/card-usd.svg";
import cardImgDKK from "../../assets/icons/card-dkk.svg";
import cardImgGBP from "../../assets/icons/card-gbp.svg";

const transactionsData = [
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
  {
    date: "15/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Completed",
  },
  {
    date: "16/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Reserved",
  },
  {
    date: "17/07/2024",
    amount: "DKK100.00",
    paymentID: "PE782478MMO",
    status: "Cancelled",
  },
];
const accounts = [
  {
    value: "eu",
    currency: "EUR",
    label: "Business card",
    account: "EUR89008837746",
    img: cardImgEUR,
  },
  {
    value: "usd",
    currency: "USD",
    label: "Business card",
    account: "USD89008837746",
    img: cardImgUSD,
  },
  {
    value: "dkk",
    currency: "DKK",
    label: "Business card",
    account: "DKK89008837746",
    img: cardImgDKK,
  },
  {
    value: "gbp",
    currency: "GBP",
    label: "Business card",
    account: "GBP89008837746",
    img: cardImgGBP,
  },
];

const ListTransactions = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [downloadTransactionsModel, setDownloadTransactionsModel] =
    useState(false);
  const [account, setAccount] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };
  const handleAccountChange = (event) => {
    setAccount(event.target.value);
  };

  const handleClearAccount = () => {
    setAccount("");
  };
  return (
    <>
      <Box sx={{ my: { xs: "60px", sm: "80px", md: "100px", lg: "117px" } }}>
        <Box sx={{ maxWidth: 374 }}>
          <FormControl fullWidth>
            <Select
              labelId="account"
              id="account"
              value={account}
              onChange={handleAccountChange}
              IconComponent={ExpandMoreRounded}
              className="commonSelect"
              renderValue={(selected) => {
                const selectedAccount = accounts.find(
                  (acc) => acc.value === selected
                );
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="h6" sx={{ mr: "6px" }}>
                        {selectedAccount.currency}
                      </Typography>
                      {selectedAccount.label}
                      <Typography variant="h6" sx={{ ml: "6px", fontSize: 12 }}>
                        {selectedAccount.account}
                      </Typography>
                    </Typography>
                  </Box>
                );
              }}
              endAdornment={
                account && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearAccount} sx={{ mr: 3 }}>
                      <Clear sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {accounts.map((acc) => (
                <MenuItem
                  key={acc.value}
                  value={acc.value}
                  sx={{
                    padding: "15px 16px",
                    fontSize: 14,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "&.MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "#eef1f1",
                      "&:hover": {
                        backgroundColor: "#eef1f1",
                      },
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="h6" sx={{ mr: "6px" }}>
                      {acc.currency}
                    </Typography>
                    {acc.label}
                    <Typography variant="h6" sx={{ ml: "6px", fontSize: 12 }}>
                      {acc.account}
                    </Typography>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "80px",
                    }}
                  >
                    <img
                      src={acc.img}
                      alt={acc.label}
                      style={{ width: 36, height: 20 }}
                    />
                    {account === acc.value && (
                      <CheckRounded sx={{ fontSize: "20px" }} />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            mt: "30px",
            backgroundColor: "secondary.main",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              padding: "16px",
            }}
          >
            <Typography variant="h3" component="h3">
              Latest transactions
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#eaeaea",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                flexGrow: 1,
              }}
            >
              {isSearchOpen ? (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search your transactions"
                  fullWidth
                  className="tableSearchInput"
                  sx={{ height: 32 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={toggleSearch}
                        sx={{ cursor: "pointer" }}
                      >
                        <SearchRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <IconButton onClick={toggleSearch} sx={{ px: 1 }}>
                  <SearchRounded />
                </IconButton>
              )}
            </Box>
            <Button
              variant="contained"
              sx={{ width: { xs: 127, md: 322 }, height: 32 }}
              onClick={() => setDownloadTransactionsModel(true)}
            >
              {isSmallScreen
                ? "Download"
                : "Download transactions as CSV or PDF"}{" "}
              <AddRounded sx={{ fontSize: "20px" }} />
            </Button>
          </Box>
          <TransactionsTable data={transactionsData} />
        </Box>
      </Box>
      <DownloadTransactionsModel
        open={downloadTransactionsModel}
        setOpen={setDownloadTransactionsModel}
        transactionsData={transactionsData}
      />
    </>
  );
};

export default ListTransactions;
