import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Allcards from "./all-cards";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Cards = () => {
  const [tabValue, setTabValue] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const menuItems = [
    {
      label: "Create a virtual card",
      action: () => console.log("Create a virtual card"),
    },
    {
      label: "Order a physical card",
      action: () => console.log("Order a physical card"),
    },
  ];

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              "@media (min-width:768px)": {
                boxShadow: "6px 6px 6px 0px #00000040",
                maxWidth: "fit-content",
                borderRadius: "5px",
              },
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="Physical cards" {...a11yProps(1)} />
              <Tab label="Virtual cards" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <Stack
            direction="row"
            spacing={"4px"}
            sx={{ cursor: "pointer" }}
            onClick={handleMenuOpen}
          >
            <AddCircleOutlineRoundedIcon sx={{ fontSize: "20px" }} />
            <Typography
              variant="body2"
              component="p"
              sx={{ cursor: "pointer" }}
            >
              Add new card
            </Typography>
          </Stack>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            className="transactionsMenu"
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  item.action();
                  handleMenuClose();
                }}
              >
                <AddRoundedIcon sx={{ fontSize: "16px" }} />
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Allcards />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          2
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          3
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Cards;
