import EastRoundedIcon from "@mui/icons-material/EastRounded";
import {
  Avatar,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import "flag-icons/css/flag-icons.min.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const accountsData = [
  {
    accountNumber: "8210 **** **** 4340",
    currency: "EUR",
    accountBalance: "€64.120,50",
    blockedAmount: "€1.960,00",
    status: 1,
    flag: "fi-eu",
  },
  {
    accountNumber: "8210 **** **** 4990",
    currency: "GBP",
    accountBalance: "£230,50",
    blockedAmount: "£60,00",
    status: 2,
    flag: "fi-gb",
  },
  {
    accountNumber: "8900 **** **** 0990",
    currency: "DKK",
    accountBalance: "DKK23.987,50",
    blockedAmount: "DKK300,00",
    status: 1,
    flag: "fi-dk",
  },
  {
    accountNumber: "3200 **** **** 0990",
    currency: "USD",
    accountBalance: "$8889,66",
    blockedAmount: "$28,99",
    status: 1,
    flag: "fi-us",
  },
];

const statusMapping = {
  1: { text: "Active", bgColor: "#23ffc6", color: "#000000" },
  2: { text: "Review", bgColor: "#fff5d9", color: "#FFBD00" },
  default: { text: "Inactive", bgColor: "#C50F2F", color: "#F00000" },
};

const ListAccountsTable = () => {
  const navigate = useNavigate();
  const handleRowClick = (accountNumber) => {
    navigate(`account-details/${accountNumber}`);
  };
  return (
    <TableContainer component={Paper} sx={{ overflowX: "auto", pr: 1 }}>
      <Table
        sx={{
          minWidth: 790,
          borderSpacing: "0 25px",
          borderCollapse: "separate",
        }}
        className="ListAccountsTable"
      >
        <TableBody>
          {accountsData.map((item, index) => {
            const status = statusMapping[item?.status] || statusMapping.default;
            return (
              <TableRow
                key={index}
                sx={{
                  boxShadow: "6px 6px 6px 0px #00000040",
                  borderRadius: "5px",
                }}
              >
                <TableCell sx={{ borderRadius: "5px" }}>
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ width: 30, height: 30 }}>
                      <span
                        className={`fi ${item.flag}`}
                        style={{ transform: "scale(2)" }}
                      ></span>
                    </Avatar>
                    <Box ml={"34px"}>
                      <Typography variant="h6">
                        {item?.accountNumber}
                      </Typography>
                      <Typography variant="body2">Account number</Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">{item?.currency}</Typography>
                  <Typography variant="body2">Currency</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">{item?.accountBalance}</Typography>
                  <Typography variant="body2">Account balance</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">{item?.blockedAmount}</Typography>
                  <Typography variant="body2">Blocked amount</Typography>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: status.bgColor,
                      color: status.color,
                      width: 70,
                      height: 26,
                      padding: 0,
                      justifyContent: "center",
                      textTransform: "none",
                      fontWeight: 700,
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: status.bgColor,
                      },
                    }}
                    size="small"
                  >
                    {status.text}
                  </Button>
                </TableCell>
                <TableCell
                  sx={{
                    position: "relative",
                    borderRadius: "0 5px 5px 0",
                    width: "65px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "primary.main",
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      width: "65px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                    onClick={() => handleRowClick(item?.accountNumber)}
                  >
                    <EastRoundedIcon sx={{ color: "#fff", fontSize: "35px" }} />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListAccountsTable;
