import { Box, CssBaseline, Toolbar } from "@mui/material";
import * as React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../footer";
import Navbar from "../navbar";
import Sidebar from "../sidebar";

const drawerWidth = 254;

export default function AppLayout({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      <Navbar handleDrawerToggle={handleDrawerToggle} />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%" },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            flexGrow: 1,
            width: { xs: "100%" },
            padding: {
              xs: "42px 25px",
              md: "50px",
              lg: "60px 40px",
              xl: "60px 70px",
              xxl: "64px 117px",
            },
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
