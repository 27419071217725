import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import ImportExportRoundedIcon from "@mui/icons-material/ImportExportRounded";
import {
  Box,
  Collapse,
  Drawer,
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/symbol.svg";

const drawerWidth = 254;

const sidebarItems = [
  { label: "Dashboard", path: "dashboard", subItems: [] },
  {
    label: "Accounts",
    path: "accounts",
    subItems: ["Sub Account 1", "Sub Account 2"],
  },
  {
    label: "Transactions",
    path: "transactions",
    subItems: ["Sub Transaction 1", "Sub Transaction 2"],
  },
  { label: "Cards", path: "cards", subItems: ["Sub Card 1", "Sub Card 2"] },
  {
    label: "Payment",
    path: "payment",
    subItems: ["Sub Payment 1", "Sub Payment 2"],
  },
  { label: "Apps", path: "apps", subItems: [] },
  { label: "Invoicing", path: "invoicing", subItems: [] },
  { label: "Earn 80€", path: "earn80", subItems: [] },
];

const companies = ["Pemmo Ltd.", "Acme Inc."];

export default function Sidebar({ mobileOpen, handleDrawerToggle, window }) {
  const location = useLocation();
  const [openItem, setOpenItem] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(companies[0]);

  const handleItemClick = (label) => {
    setOpenItem((prev) => (prev === label ? null : label));
  };

  const handleCompanyChange = (event) => {
    if (event.target.value !== "+ Add new company") {
      setSelectedCompany(event.target.value);
    }
  };

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        backgroundColor: "secondary.main",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "36px 22px",
          marginBottom: "",
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{ width: 25, height: 25, marginRight: 12, cursor: "pointer" }}
        />
        <Box sx={{ transform: "translateY(10px)" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 18,
              fontWeight: 700,
              lineHeight: "18px",
              letterSpacing: "0.16px",
            }}
          >
            All your financials
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: 18,
              fontWeight: 700,
              color: "primary.main",
              textAlign: "end",
              lineHeight: "18px",
              letterSpacing: "0.16px",
            }}
          >
            one place
          </Typography>
        </Box>
      </Box>
      <List sx={{ flexGrow: 1 }} className="sidebarList">
        {sidebarItems.map(({ label, path, subItems }) => (
          <Fragment key={label}>
            <ListItemButton
              component={NavLink}
              to={`/${path}`}
              sx={{
                "&.active": {
                  backgroundColor: "primary.main",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                },
              }}
              isActive={(match, location) => location.pathname === `/${path}`}
              onClick={() => handleItemClick(label)}
            >
              <ListItemText primary={label} />
              {subItems.length > 0 ? (
                openItem === label ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItemButton>
            {subItems.length > 0 && (
              <Collapse in={openItem === label} timeout="auto" unmountOnExit>
                <List disablePadding className="innerListSidebar">
                  {subItems.map((subItem, index) => (
                    <ListItemButton
                      key={index}
                      component={NavLink}
                      to={`/${path}/sub${index + 1}`}
                      sx={{
                        "&.active": {
                          backgroundColor: "primary.main",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                        },
                      }}
                      isActive={(match, location) =>
                        location.pathname === `/${path}/sub${index + 1}`
                      }
                    >
                      <ListItemIcon>
                        <FiberManualRecordOutlinedIcon
                          sx={{ fontSize: "16px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={subItem} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>
      <Box>
        <FormControl fullWidth>
          <Select
            value={selectedCompany}
            onChange={handleCompanyChange}
            displayEmpty
            renderValue={(selected) => selected}
            IconComponent={ExpandMoreRoundedIcon}
            className="sidebarSelect"
            MenuProps={{
              PaperProps: {
                sx: {
                  left: "0 !important",
                  bottom: "48px !important",
                  top: "unset !important",
                },
              },
            }}
          >
            {companies.map((company, index) => (
              <MenuItem
                key={index}
                value={company}
                sx={{
                  padding: "15px 16px",
                  fontSize: 14,
                  fontWeight: 400,
                  letterSpacing: "0.16px",
                  lineHeight: "18px",
                  display: "flex",
                  justifyContent: "space-between",
                  ".MuiSvgIcon-root": {
                    color: "#000",
                  },
                  "&.MuiMenuItem-root.Mui-selected": {
                    backgroundColor: "#0a38f0",
                    color: "white",
                    ".MuiSvgIcon-root": {
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#0a38f0",
                    },
                  },
                }}
              >
                {company}
                <ImportExportRoundedIcon />
              </MenuItem>
            ))}
            <MenuItem
              disabled
              sx={{
                padding: "15px 16px",
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: "0.16px",
                lineHeight: "18px",
                color: "common.black",
                "&.Mui-disabled": {
                  border: "1px solid transparent",
                  borderTopColor: "#0a38f0",
                  borderBottomColor: "#0a38f0",
                  opacity: 1,
                  cursor: "pointer",
                },
              }}
            >
              + Add new company
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        width: { lg: drawerWidth },
        flexShrink: { lg: 0 },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: 0,
            background: "transparent",
            boxShadow: "unset",
            borderRadius: 0,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: 0,
            background: "transparent",
            boxShadow: "unset",
            borderRadius: 0,
          },
          border: 0,
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
